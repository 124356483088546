<template>
  <div id="home-container">
    <nav-indicator :currIndex="activeIndex" :navs="navs" @jumpToPage="jumpAction" />
    <Transition :name="transitionDirection">
      <landing v-if="activeIndex === 0" @takeTour="moveDown()" />
      <leaderbord v-else-if="activeIndex === 1" />
      <challenges-and-battles v-else-if="activeIndex === 2" />
      <fanbase v-else-if="activeIndex === 3" />
      <profile-design v-else-if="activeIndex === 4" />
      <upcoming-project v-else-if="activeIndex === 5" />
      <footer-page v-else-if="activeIndex === 6" />
    </Transition>
  </div>
</template>

<script>
import ChallengesAndBattles from "../components/home/ChallengesAndBattles.vue";
import Fanbase from "../components/home/Fanbase.vue";
import FooterPage from "../components/home/FooterPage.vue";
import Landing from "../components/home/Landing.vue";
import Leaderbord from "../components/home/Leaderbord.vue";
import ProfileDesign from "../components/home/ProfileDesign.vue";
import UpcomingProject from "../components/home/UpcomingProject.vue";

import NavIndicator from "../components/NavIndicator.vue";

export default {
  name: "Home",
  components: {
    Landing,
    Fanbase,
    Leaderbord,
    ChallengesAndBattles,
    FooterPage,
    ProfileDesign,
    UpcomingProject,
    NavIndicator
  },
  data() {
    return {
      activeIndex: 0,
      scrollDisabled: false,
      transitionDirection: "",
      navs: ["Home", "Leaderboard", "Challenges", "Fanbase", "Profile", "Marketplace", "Joins us!"],
      navsHashs: [
        "", // home needs no hash
        "#leaderboard",
        "#challenges",
        "#fanbase",
        "#profile",
        "#marketplace",
        "#joins"
      ]
    };
  },
  mounted() {
    // jump to component when hash is set in url
    const hash = this.$route.hash.replace("#", "").toLowerCase();
    const pageIndex = this.navs.findIndex(navRoute => {
      return navRoute.toLowerCase().includes(hash);
    });
    if (pageIndex === -1) {
      this.activeIndex = 0;
      this.$router.push({ path: "/", hash: "" });
    } else {
      this.activeIndex = pageIndex;
    }

    // init all the listeners to move between components
    const vm = this;

    function mouseWheel(e) {
      e.preventDefault();

      if (!vm.scrollDisabled) {
        if (e.deltaY > 0) {
          vm.moveDown();
        }
        if (e.deltaY < 0) {
          vm.moveUp();
        }
        vm.scrollDisabled = true;

        setTimeout(() => {
          vm.scrollDisabled = false;
        }, 200);
      }
    }

    let startTouchY = 0;
    function touchScrollStart(e) {
      startTouchY = e.changedTouches[0].clientY;
    }

    function touchScrollEnd(e) {
      const endTouchY = e.changedTouches[0].clientY;
      const deltaY = startTouchY - endTouchY;

      if (!vm.scrollDisabled) {
        if (deltaY > 125) {
          vm.moveDown();
        }
        if (deltaY < -125) {
          vm.moveUp();
        }
        vm.scrollDisabled = true;

        setTimeout(() => {
          vm.scrollDisabled = false;
        }, 250);
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function() {
            return (supportsPassive = true);
          }
        })
      );
    } catch (e) {
      console.log("error", e);
    }

    const container = document.getElementById("home-container");

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    container.addEventListener("DOMMouseScroll", mouseWheel, false); // older FF
    container.addEventListener(wheelEvent, mouseWheel, wheelOpt); // modern desktop
    container.addEventListener("touchstart", touchScrollStart, wheelOpt); // mobile
    container.addEventListener("touchend", touchScrollEnd, wheelOpt); // mobile
    window.addEventListener("keydown", vm.keyScroll, false);
  },
  methods: {
    jumpAction(index) {
      if (index < this.activeIndex) {
        this.transitionDirection = "slide-down";
      } else {
        this.transitionDirection = "slide-up";
      }

      if (index < this.navs.length && index >= 0) {
        this.activeIndex = index;
      }

      this.$router.push({ path: "/", hash: this.navsHashs[this.activeIndex] });
    },
    moveDown() {
      this.jumpAction(this.activeIndex + 1);
    },
    moveUp() {
      this.jumpAction(this.activeIndex - 1);
    },
    keyScroll(e) {
      // up: 38, down: 40
      // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
      var keys = { 33: 1, 34: 1, 35: 1, 36: 1, 37: 1, 38: 1, 40: 1 };

      if (keys[e.keyCode]) {
        e.preventDefault();

        if (e.keyCode === 33 || e.keyCode === 38) {
          this.moveUp();
        }
        if (e.keyCode === 34 || e.keyCode === 40) {
          this.moveDown();
        }
        return false;
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.keyScroll);
  }
};
</script>

<style scoped lang="scss">
// slide up transistion
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.8s;
  transition-delay: 0.1s;
}

.slide-up-enter-from {
  opacity: 1;
  transform: translateY(100vh);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-100vh);
}

// slide down transition
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.6s;
}

.slide-down-enter-from {
  opacity: 1;
  transform: translateY(-100vh);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translateY(100vh);
}
</style>
