<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h1 name="header">
              We Released the App!
            </h1>
          </div>

          <div class="modal-body">
            Join the Talentsverse community now! We are excited to have you on board!
            <br /><br />
            Download now and use the referal code:
            <span class="referalCode">Talents2023</span>
          </div>

          <div class="modal-footer">
            <div class="linkContainer">
              <a href="https://apps.apple.com/de/app/talentsverse/id6450220207" target="_blank">
                <img src="@/assets/img/iosIcon.svg" class="linkIcon" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.talents.talentsverse"
                target="_blank"
              >
                <img src="@/assets/img/androidIcon.svg" class="linkIcon" />
              </a>
            </div>

            <div class="close-btn" @click="$emit('close')">
              <img src="@/assets/img/closeBtn.svg" style="height: 2rem; width: 2rem;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 85vw;
  margin: 0px auto;
  z-index: 9998;
  @media (max-width: 850px) {
    padding: 50px 20px 10px 20px;
  }
  padding: 100px 30px 15px 30px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h1 {
  margin-top: 0;
  @media (max-width: 850px) {
    font-size: 2.5rem;
  }
  font-size: 5rem;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
  background-clip: text;
}

.modal-body {
  margin: 20px 0;
  @media (max-width: 850px) {
    font-size: 1.2rem;
  }
  font-size: 2rem;
  font-weight: bold;

  .referalCode {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
    background-clip: text;
  }
}

.modal-footer {
  padding-top: 40px;
  width: 100%;

  .linkContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    @media (max-width: 850px) {
      gap: 5rem;
    }
    gap: 12rem;
  }
  .linkIcon {
    @media (max-width: 850px) {
      height: 5rem;
      width: 5rem;
    }
    height: 8rem;
    width: 8rem;
  }

  .close-btn {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: auto;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
