<template>
  <div class="full-page">
    <div class="learn-more">

      <learn-more-btn path="/learn-more/upcoming-project" />
    </div>
    <div class="content-container">
      <h1 class="header-text">
        NFT Marketplace
      </h1>
      <div class="white-gradient"></div>
      <div class="nft-marketplace-container">
        <div class="navigation-area">
          <nav class="nft-marketplace-nav">
            <img class="logo" src="@/assets/img/logos/TalentsverseLogo.svg" />
            <div class="search-bar">
              <div class="input-wrapper">
                <input class="input-field" type="text" placeholder="Browse The Marketplace..." />
                <img class="search-icon" src="@/assets/img/upcomingProject/search-icon.svg" />
              </div>
            </div>
            <ul class="nav-links">
              <li><p>Explore</p></li>
              <li><p>Stats</p></li>
              <li><p>Ressources</p></li>
              <li><p>Create</p></li>
            </ul>
            <img class="profile-icon" src="@/assets/img/upcomingProject/profile-icon.svg" />
            <img class="wallet-icon" src="@/assets/img/upcomingProject/wallet-icon.svg" />
          </nav>
          <div class="category-container">
            <h1 class="category-heading">NFT Marketplace</h1>
            <ul class="category-links">
              <li>Trending</li>
              <li>Top</li>
              <li>Dance</li>
              <li>Sports</li>
              <li>Music</li>
              <li>Model</li>
              <li>Others</li>
            </ul>
          </div>
        </div>
        <div class="main-area">
          <div class="card-grid-container">
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Moderator.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Dunk.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  25   <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/FreestyleSoccer.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/nft1.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/dance1.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/DanceCoreo.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Musician.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/model.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/nft2.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/breakdancing.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>

            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Moderator.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Dunk.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  25   <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/FreestyleSoccer.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/nft1.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/dance1.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/DanceCoreo.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/Musician.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/model.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/nft2.png" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <img class="card-image" src="@/assets/img/upcomingProject/breakdancing.jpg" />
              <div class="info-container">
                <ul class="card-identification">
                  <li>collection</li>
                  <li>name</li>
                  <li>numberID</li>
                </ul>
                <ul class="card-values">
                  <li>
                    price
                    <img class="icon" src="@/assets/img/upcomingProject/talentumIcon-small.svg" />
                  </li>
                  <li>
                    likes <img class="icon" src="@/assets/img/upcomingProject/likeIcon-small.svg" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreBtn from "../LearnMoreBtn.vue";
export default {
  name: "UpcomingProjectScreen",
  components: {
    LearnMoreBtn
  }
};
</script>

<style lang="scss" scoped>

.learn-more{
  z-index: 100;
  position: absolute;
  bottom: 75px;
  right: 125px;
  @media (max-width: 1200px) {
    bottom: 25px;
    right: 25px;
  }
  @media (max-width: 850px) {
    bottom: 10px;
    right: 10px;
  }
}
.content-container {
  @media (min-width: 500px) {
    margin-top: 10rem;
  }
  margin-top: 6rem;
  margin-bottom: 2em;
  display: grid;
  justify-self: center;
  .white-gradient {
    z-index: 10;
    height: 50%;
    width: 98%;
    margin-bottom: 0.6%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgb(255, 255, 255, 0));
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    align-self: end;
    justify-self: center;
    pointer-events: none;
  }
  .header-text {
    z-index: 11;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    align-self: end;
    max-width: 50vw;
    @media (min-width: 850px) {
      font-size: 3.5em;
    }
    font-size: 1.7em;
    font-weight: bold;
    text-align: left;
    background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 1.75em;
    pointer-events: none;
  }
  .nft-marketplace-container {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 90vw;
    overflow: hidden;
    align-self: end;
    justify-self: center;
    row-gap: 20px;
    border: double 8px transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(140deg, rgb(255, 233, 5), rgb(255, 46, 9), rgb(255, 233, 5), rgb(255, 46, 9));
    background-origin: border-box;
    background-clip: content-box, border-box;
    .navigation-area {
      height: 110px;
      overflow: hidden;
      .nft-marketplace-nav {
        z-index: 0;
        display: flex;
        border-radius: 0 0 35px 35px;
        border-bottom: double 4px transparent;
        background-image: linear-gradient(white, white),
          linear-gradient(
            140deg,
            rgb(255, 233, 5),
            rgb(255, 46, 9),
            rgb(255, 233, 5),
            rgb(255, 46, 9)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        .logo {
          height: 2em;
          align-self: center;
          padding-left: 1.5em;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
        .search-bar {
          display: flex;
          align-self: center;
          flex-grow: 15;
          border: 0px;
          justify-content: center;
          .input-wrapper {
            display: flex;
            border-radius: 15px;
            border: 2px solid #aaa;
            width: 80%;
            justify-content: space-between;
            .input-field {
              border: 0;
              border-radius: 25px;
              background: transparent;
              padding: 5px;
              padding-left: 15px;
            }
            .search-icon {
              width: 1rem;
              padding-right: 10px;
            }
          }
        }

        .nav-links {
          display: flex;
          margin: 0;
          padding: 0;
          text-align: right;
          flex-grow: 1;
          li {
            color: #aaa;
            display: inline-block;
            padding: 0px 20px 0 20px;
            font-weight: bold;
            margin-top: auto;
            height: 100%;
          }
          li:hover {
            background-image: linear-gradient(140deg, rgb(255, 233, 5), rgb(255, 46, 9));
            color: white;
            font-weight: bold;
          }
        }
        .profile-icon {
          width: 35px;
          padding-right: 2rem;
        }
        .wallet-icon {
          width: 35px;
          padding-right: 2rem;
        }
      }
      .category-container {
        z-index: -2;
        display: flex;
        min-width: 840px;
        flex-wrap: nowrap;
        border-bottom: double 4px transparent;
        background-image: linear-gradient(white, white),
          linear-gradient(
            140deg,
            rgb(255, 233, 5),
            rgb(255, 46, 9),
            rgb(255, 233, 5),
            rgb(255, 46, 9)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
        box-shadow: 0px 5px 5px rgba(142, 187, 255, 0.75);
        .category-heading {
          width: 175px;
          font-size: 1.25rem;
          margin-left: 0.5em;
          padding-right: 5px;
          border-right: solid 2px black;
        }
        .category-links {
          text-align: left;
          flex-grow: 1;
          margin: 0;
          padding: 0;
          padding-left: 1em;
          li {
            display: inline-block;
            padding: 18px 20px 0 20px;
            font-weight: bold;
            margin-top: auto;
            height: 2em;
          }
          li:hover {
            background-image: linear-gradient(140deg, rgb(255, 233, 5), rgb(255, 46, 9));
            color: white;
            font-weight: bold;
          }
        }
      }
    }
    .main-area {
      height: 55vh;
      .card-grid-container {
        display: flex;
        flex-wrap: wrap;
        overflow-y: hidden;
        width: 100%;
        gap: 10px;
        padding: 10px;
        .card {
          z-index: 0;
          display: flex;
          height: 220px;
          width: 155px;
          flex-direction: column;
          border-radius: 25px;
          border: double 4px transparent;
          background-image: linear-gradient(white, white),
            linear-gradient(
              140deg,
              rgb(255, 233, 5),
              rgb(255, 46, 9),
              rgb(255, 233, 5),
              rgb(255, 46, 9)
            );
          background-origin: border-box;
          background-clip: content-box, border-box;
          box-shadow: 0px 0px 20px rgba(142, 187, 255, 0.65);

          img {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
          }
          .info-container {
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 20%;
            background: white;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            box-shadow: 0px -5px 10px rgba(142, 187, 255, 0.5);
            ul {
              margin: 0;
              list-style: none;
              font-size: 0.75rem;
              margin-top: 0.5em;
              margin-bottom: 0.5em;
            }
            .card-identification {
              margin-right: auto;
              padding-left: 1em;
              padding-top: 0;
              li {
                text-align: left;
              }
              li:nth-of-type(2) {
                font-weight: bold;
              }
              li:nth-of-type(3) {
                font-weight: bold;
              }
            }
            .card-values {
              text-align: right;
              padding-right: 1em;
              li {
                display: flex;
                font-size: 0.8rem;
                img {
                  width: 1.0em;
                  height: 1.0em;
                  margin-left: 2px;
                }
              }
            }
          }
        }
        .card:hover {
          animation: cardHover 0.1s linear forwards;
        }
        @keyframes cardHover {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
</style>
