<template>
  <div id="indicator" class="indicator">
    <div class="background" :class="{ big: showText }" />
    <div
      v-for="(nav, index) in navs"
      :key="nav"
      class="nav-item"
      @click="$emit('jumpToPage', index)"
    >
      <div class="dot" :class="index === currIndex ? 'filled' : ''" />
      <div class="text" :class="{ show: showText, bold: index === currIndex }">
        {{ nav }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavIndicator",
  emits: ["jumpToPage"],
  props: {
    currIndex: {
      required: true,
      type: Number
    },
    navs: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      showText: false,
      triggerNr: 0
    };
  },
  mounted() {
    this.triggerText();
    const vm = this;

    const element = document.getElementById("indicator");
    element.onmouseenter = () => {
      vm.showText = true;
    };
    element.onmouseleave = () => {
      vm.triggerText(1000);
    };
  },
  watch: {
    currIndex() {
      this.triggerText();
    }
  },
  methods: {
    triggerText(timeout = 3000) {
      this.showText = true;

      // (trigger nr) to reset the trigger when triggered multiple times
      this.triggerNr += 1;
      const currTriggerNr = this.triggerNr;
      const vm = this;

      setTimeout(() => {
        if (currTriggerNr === vm.triggerNr) {
          vm.showText = false;
        }
      }, timeout);
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator {
  position: fixed;
  z-index: 25;
  left: 5px;
  top: 42%;

  @media (max-width: 999px) {
    .background {
      display: none;
    }
  }

  .background {
    z-index: -5;
    opacity: 0;
    border-radius: 20px;
    filter: blur(5px);
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    transition: opacity 1s;

    &.big {
      opacity: 0.8;
    }
  }

  .nav-item {
    margin-top: 5px;
    display: flex;
    gap: 5px;

    @media (min-width: 1000px) {
      .text {
        font-size: 1.2em;
        text-align: left;
        color: rgb(42, 42, 42);
        opacity: 0;
        font-weight: normal;
        transition: opacity 1s;

        &.show {
          opacity: 1;
        }

        &.bold {
          font-weight: bold;
        }
      }
    }

    @media (max-width: 999px) {
      .text {
        display: none;
      }
    }

    .dot {
      @media (max-width: 999px) {
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
      width: 15px;
      height: 15px;
      border-radius: 15px;
      border: 1px solid rgb(255, 51, 0);

      &.filled {
        background-color: rgb(255, 51, 0);
      }
    }
  }
}
</style>
