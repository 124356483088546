<template>
  <div class="full-page">
    <img class="background-lines" src="@/assets/img/landingPage/BackgroundLinesDiagonal.svg" />
    <img class="background-triangles" src="@/assets/img/landingPage/DecoBottomTriangles.svg" />
    <img class="background-stripes" src="@/assets/img/landingPage/BackgroundLinesVertical.svg" />
    <img class="background-star" src="@/assets/img/landingPage/StarOutline.svg" />
    <div class="tour-box" @click="$emit('takeTour')">
      <div class="tour-text">Take the tour</div>
      <img class="tour-btn" src="@/assets/img/landingPage/TakeTheTourIcon.svg" />
    </div>
    <div class="content-container">
      <img class="devices-img" src="@/assets/img/landingPage/Devices.svg" />
      <div class="transparent-container">
        <img src="@/assets/img/logos/STERN.svg" alt="star" class="talents-logo2" />
        <div class="text-box">
          <img src="@/assets/img/logos/TalentsverseLogo.svg" alt="star" class="talents-logo" />
          <div class="big-text">For Talented People Of Every Kind.</div>
          <div class="small-text">
            Join our <span>Talentsverse</span> and <span>grow</span> your <span>Talent</span>.
          </div>
        </div>
      </div>
    </div>
    <div class="video-loop">
      <video muted="true" autoplay nocontrols playsinline loop class="video1">
        <source src="@/assets/img/landingPage/video1.mp4" type="video/mp4" />
      </video>
      <video muted="true" autoplay nocontrols playsinline loop class="video2">
        <source src="@/assets/img/landingPage/video2.mp4" type="video/mp4" />
      </video>
      <video muted="true" autoplay nocontrols playsinline loop class="video3">
        <source src="@/assets/img/landingPage/video3.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  emits: ["takeTour"]
};
</script>

<style lang="scss" scoped>
@media (min-width: 1000px) {
  .content-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    right: 8%;
    top: 19%;
    width: 43vw;

    .devices-img {
      width: 20%;
      align-self: flex-end;
      padding-bottom: 0.5vw;
    }

    .transparent-container {
      box-shadow: 0px 0px 2vw rgba(142, 187, 255, 0.7);
      background-color: rgb(255, 255, 255, 0.8);
      transition: 0.1s;
      animation: pulse 5s ease-in-out infinite alternate;

      @keyframes pulse {
        from {
          box-shadow: 0px 0px 2vw rgba(142, 187, 255, 0.7);
        }
        to {
          box-shadow: 0px 0px 2vw white;
        }
      }

      .talents-logo2 {
        display: none;
      }

      .text-box {
        padding: 1vw 1vw 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 2vw;

        .talents-logo {
          width: 20%;
        }

        .big-text {
          font-family: "Noto Sans", sans-serif;
          font-size: 3.5vw;
          text-align: left;
          font-weight: bold;
          align-self: center;
          background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .small-text {
          font-size: 1.325vw;
          text-align: left;
          padding-top: 1vw;
          padding-bottom: 2vw;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  @keyframes videoLoop {
    0% {
      left: 800px;
      bottom: 100px;
      opacity: 0;
    }
    10% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      left: -300px;
      bottom: 25px;
    }
  }

  .video-loop {
    position: absolute;
    height: 100vh;
    width: 100vw;
    bottom: 100px;
    z-index: -2;

    --animation-dur: 20s;

    video {
      clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%);
      height: 44vh;

      position: absolute;
      animation-name: videoLoop;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: var(--animation-dur);

      @media (min-height: 800px) {
        height: 32vh;
      }
      @media (min-height: 1000px) {
        height: 27vh;
      }
    }

    .video2 {
      animation-delay: calc(var(--animation-dur) * -0.33);
    }

    .video3 {
      animation-delay: calc(var(--animation-dur) * -0.66);
    }
  }
}

@media (min-width: 1000px) {
  @keyframes videoLoop {
    0% {
      top: -400px;
      left: 30vw;
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: 80vh;
      left: -100px;
    }
  }

  .video-loop {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -2;

    --animation-dur: 30s;

    video {
      clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%);
      height: 38vh;
      @media (min-width: 1200px) {
        height: 40vh;
      }
      @media (min-width: 1300px) {
        height: 42vh;
      }
      @media (min-width: 1500px) {
        height: 40vh;
      }
      @media (min-width: 1700px) {
        height: 40vh;
      }
      @media (min-width: 1900px) {
        height: 35vh;
      }
      @media (min-width: 2100px) {
        height: 35vh;
      }

      position: absolute;
      animation-name: videoLoop;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: var(--animation-dur);
    }

    .video2 {
      animation-delay: calc(var(--animation-dur) * -0.33);
    }

    .video3 {
      animation-delay: calc(var(--animation-dur) * -0.66);
    }
  }
}

@media (max-width: 1000px) {
  .devices-img {
    display: none;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8vh;
    padding: 100px 25px 0 25px;

    .transparent-container {
      box-shadow: 0px 0px 5vw #8ebbff;
      position: relative;
      background-color: rgba(251, 251, 251, 0.8);
      max-width: 95%;
      transition: 0.1s;
      padding: 20px;

      .talents-logo2 {
        max-width: 30%;
      }
      .text-box {
        padding: 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .talents-logo {
          display: none;
        }

        .big-text {
          font-size: min(9vw, 400%);
          width: 80%;
          font-weight: bold;
          background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .small-text {
          width: 80%;
          font-size: 5vw;
        }
      }
    }
  }
}

////////////////////////////////////

.background-lines {
  position: absolute;
  top: -200px;
  width: 65vw;
  left: 20px;
  z-index: -3;
  transform: rotate(-10deg);
}

.background-triangles {
  z-index: 1;
  position: absolute;
  width: 100vw;
  bottom: 0;
  left: 0;
}

.background-stripes {
  z-index: 0;
  position: absolute;
  bottom: 0;
  height: 20vh;
  width: 100vw;
  left: 0;
}

.background-star {
  z-index: -3;
  height: 300vh;
  width: 100vw;
  position: absolute;
  bottom: -100%;
  left: 30%;
  animation: spin 15s ease-in-out alternate infinite;
  filter: blur(3px);
  opacity: 75%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@media (min-width: 875px) {
  .tour-box {
    z-index: 2;
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2.25vw;
    animation: wiggle 1.5s ease-in-out infinite alternate;
    .tour-text {
      font-size: 1.2vw;
      text-transform: uppercase;
    }
    .tour-btn {
      width: 3%;
      user-select: none;
      margin: auto;
      transition: 0.3s;
    }
    .tour-btn:hover {
      transform: rotate(360deg);
    }
  }
  @keyframes wiggle {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(10px);
    }
  }
}

@media (max-width: 875px) {
  .tour-box {
    z-index: 2;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 3px;

    .tour-text {
      font-size: 25px;
      text-transform: uppercase;
    }

    .tour-btn {
      user-select: none;
      padding: 0.1em;
      margin: auto;
      width: 50px;
      transition: 0.3s;
    }
    .tour-btn:hover {
      background-color: rgb(195, 195, 195);
    }
  }
}
</style>
