<template>
  <div class="button" @click="gotoPath()">
    <div class="button-background" />
    <div class="text">
      Learn more ➤
    </div>
  </div>
</template>

<script>
export default {
  name: "LearnMoreBtn",
  props: {
    path: {
      required: true,
      type: String
    }
  },
  methods: {
    gotoPath() {
      this.$router.push(this.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
}

.button {
  @media (max-width: 850px) {
    padding: 2px 10px 2px 10px;
  }
  padding: 5px 15px 5px 15px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));

  .button-background {
    height: 110%;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: 0;
    top: 0;
    background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));
    filter: blur(10px);
    transition: 1s;
  }

  &:hover .button-background {
    filter: blur(20px);
  }

  .text {
    position: relative;
    color: white;
    white-space: nowrap;
    @media (min-width: 850px) {
      font-size: 30px;
    }
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
}

@keyframes pulse {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(30px);
  }
  100% {
    filter: blur(10px);
  }
}
</style>
