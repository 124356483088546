<template>
  <div style="position: relative;">
    <img class="background-icons" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />
    <div class="content-container">
      <div class="header">
        Talentsverse Blog
      </div>
      <div v-if="blogs" class="blog-container">
        <div v-for="blog in blogs" v-bind:key="blog.sys.id" class="blog">
          <img class="image" :src="blog.fields.image.fields.file.url" />
          <div class="content">
            <div class="author-container">
              <div class="title">
                {{ blog.fields.title }}
              </div>
            </div>
            <div class="time">
              {{ formatTime(blog.sys.createdAt) }} from {{ blog.fields.author }}
            </div>
            <div class="content-text" v-html="documentToHtmlString(blog.fields.content)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ctf from "../contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default {
  name: "Blog",
  data: () => ({
    blogs: null,
    documentToHtmlString: documentToHtmlString
  }),
  async mounted() {
    this.blogs = (await ctf.fetchBlogs()).items;
  },
  methods: {
    formatTime(time) {
      const date = new Date(time); // use your own date object here
      const formattedDate = date.toLocaleString("en-GB", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });

      return formattedDate;
    }
  }
};
</script>

<style scoped lang="scss">
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
  }
  padding: 100px 0 0 0;
  width: 100vw;
  height: calc(100vh - 100px);
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 1rem;

  .header {
    @media (max-width: 600px) {
      font-size: 32px;
    }
    font-size: 40px;
    font-weight: bold;
  }

  .blog-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    align-items: center;
    margin: 0 3rem 5rem 3rem;
  }

  .blog {
    width: 100%;
    max-width: 800px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    .content {
      @media (max-width: 600px) {
        padding: 1rem;
      }
      padding: 2rem;
    }

    .title {
      @media (max-width: 600px) {
        font-size: 24px;
        padding-right: 0;
      }
      text-align: left;
      font-size: 32px;
      font-weight: bold;
      padding-right: 4rem;
    }

    .image {
      width: 100%;
      height: 300px;
      @media (max-width: 600px) {
        height: 150px;
      }
      border-radius: 0.5rem 0.5rem 0 0;
      object-fit: cover;
    }

    .time {
      text-align: left;
      @media (max-width: 600px) {
        font-size: 13px;
      }
      font-size: 15px;
      color: #888;
    }
    .content {
      margin-top: 0.25rem;
      text-align: left;
      @media (max-width: 600px) {
        font-size: 16px;
      }
      font-size: 18px;
    }
  }
}

.background-icons {
  z-index: -5;
  position: absolute;
  opacity: 0.1;
  left: -4000px;
  bottom: 0px;
  height: 5000px;
  user-select: none;
  overflow: hidden;
}
</style>
