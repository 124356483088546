<template>
  <div class="full-page">
    <img class="background-ConnectedDots" src="@/assets/img/fanbase/ConnectedDots.svg" />

    <div class="content-container">
      <div class="header-text">
        <div class="colored-text">
          Your Benefits When You Join Us
        </div>
        <div class="learnMore">
          <learn-more-btn path="/learn-more/fanbase" />
        </div>
      </div>

      <div class="video-box">
        <img class="phone-overlay" src="@/assets/img/fanbase/VideoOverlay.svg" />
        <video muted="true" autoplay nocontrols playsinline loop class="phone-video">
          <source src="@/assets/img/fanbase/sampleVideo1.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreBtn from "../LearnMoreBtn.vue";
export default {
  name: "FanbaseScreen",
  components: {
    LearnMoreBtn
  }
};
</script>

<style lang="scss" scoped>
.learnMore {
  z-index: 10;
  padding: 10px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    position: absolute;
    bottom: 15px;
    right: -85px;
  }
}

@media (min-width: 900px) {
  .content-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 130px 150px 75px 75px;
    width: calc(100vw - 225px);
    //height: calc(100vh - 130px);
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    z-index: 0;

    .header-text {
      max-width: 45vw;
      align-self: flex-end;
      font-size: 4em;
      font-weight: bold;
      text-align: left;
    }

    .video-box {
      position: relative;

      .phone-overlay {
        position: absolute;
        width: 20vw;
        height: 40vw;
        object-fit: cover;
        border-radius: 30px;
      }

      .phone-video {
        width: 20vw;
        height: 40vw;
        object-fit: cover;
        border-radius: 30px;
        border-radius: 35px;
        border: double 5px transparent;
        background-image: linear-gradient(white, white),
          linear-gradient(
            140deg,
            rgb(255, 233, 5),
            rgb(255, 46, 9),
            rgb(255, 233, 5),
            rgb(255, 46, 9)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
      }
    }
  }
}

@media (max-width: 900px) {
  .content-container {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
    align-items: center;
    flex-direction: column;
    display: flex;
    z-index: 0;

    .header-text {
      width: 95%;
      margin-bottom: 0.5em;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }

    .video-box {
      position: relative;
      --frame-height: 65vh;

      .phone-overlay {
        position: absolute;
        width: calc(var(--frame-height) / 2);
        height: calc(var(--frame-height));
        object-fit: cover;
        border-radius: 30px;
      }

      .phone-video {
        width: calc(var(--frame-height) / 2);
        height: calc(var(--frame-height) + 1vh);
        object-fit: cover;
        border-radius: 30px;
        border-radius: 30px;
        border: double 5px transparent;
        background-image: linear-gradient(white, white),
          linear-gradient(
            140deg,
            rgb(255, 233, 5),
            rgb(255, 46, 9),
            rgb(255, 233, 5),
            rgb(255, 46, 9)
          );
        background-origin: border-box;
        background-clip: content-box, border-box;
      }
    }
  }
}

.background-ConnectedDots {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: -5;
  @media (max-width: 1080px) {
    filter: blur(2px);
  }
}
</style>
