<template>
  <div class="button">
    <div class="button-background" @click="gotoPath()" />
    <div class="text" @click="gotoPath()">
      Go Back
    </div>
  </div>
</template>

<script>
export default {
  name: "GoBackBtn",
  props: {
    hash: {
      required: true,
      type: String
    }
  },
  methods: {
    gotoPath() {
      this.$router.push({ path: "/", hash: this.hash });
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1000px) {
  .button {
    z-index: 100;
    height: 100vh;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));

    .button-background {
      height: 100vh;
      width: 50px;
      position: absolute;
      left: 0;
      z-index: 0;
      top: 0;
      background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));
      filter: blur(10px);
      transition: 1s;
    }

    &:hover .button-background {
      filter: blur(20px);
      width: 55px;
    }

    @keyframes pulse{
      0%{
        transform: scale(0.95);
        opacity: 0.95;
      }
      100%{
        transform: scale(2.5);
        opacity: 0;
      }
    }

    .text {
      position: relative;
      color: white;
      white-space: nowrap;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      top: 47%;
      left: 0;
      transform: rotate(90deg);
    }

    .icon {
      display: none;
    }
  }
}

@media (max-width: 999px) {
  .button {
    z-index: 100;
    height: 70px;
    width: 70px;
    position: absolute;
    bottom: 25px;
    left: 15px;
    border-radius: 50px;
    background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));

    .button-background {
      height: 75px;
      width: 75px;
      position: absolute;
      z-index: -2;
      bottom: 0;
      left: 0;
      border-radius: 70px;
      background-image: linear-gradient(0, rgb(255, 46, 9), rgb(255, 119, 8));
      filter: blur(10px);
      transition: filter 1s;
    }

    &:hover .button-background {
      filter: blur(20px);
    }

    .text {
      position: relative;
      color: white;
      word-wrap: break-word;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      top: 20%;
      right: 0;
    }
  }
}
</style>
