<template>
  <div class="full-page">
    <div class="background-icons-1">
      <img
        class="background-icons-1-img"
        src="@/assets/img/BattlesAndChallenges/IconCollection.svg"
      />
    </div>
    <img class="background-icons-2" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />
    <img class="background-icons-3" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />
    <div class="content-container">
      <div class="battle-box">
        <div class="footer-text">Battle And Challenge Other Talents</div>
        <div class="number-left number">
          {{ Math.round(number1) }}
          <img src="@/assets/img/STERN.png" style="height: 1.8rem" />
        </div>
        <div class="number-right number">
          {{ Math.round(number2) }}
          <img src="@/assets/img/STERN.png" style="height: 1.8rem" />
        </div>
        <div class="challenge-box" id="left-challenge-box">
          <div class="img-box">
            <video muted="true" autoplay nocontrols playsinline loop class="challenge-img">
              <source src="@/assets/img/BattlesAndChallenges/battle1.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <img class="moderator" src="@/assets/img/mascots/Moderator.png" />
        <img class="swords" src="@/assets/img/BattlesAndChallenges/SwordsBattlesIcon.svg" />
        <div class="challenge-box" id="right-challenge-box">
          <div class="img-box">
            <video muted="true" autoplay nocontrols playsinline loop class="challenge-img">
              <source src="@/assets/img/BattlesAndChallenges/battle2.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div class="learnMore">
          <learn-more-btn path="/learn-more/challengesAndBattles" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreBtn from "../LearnMoreBtn.vue";

export default {
  name: "ChallengesAndBattleScreen",
  components: {
    LearnMoreBtn
  },
  data() {
    return {
      number1Max: Math.random() * 1000 + 1000,
      number2Max: Math.random() * 1000 + 1000,
      number1: 0,
      number2: 0
    };
  },
  async mounted() {
    await new Promise(resolve => setTimeout(resolve, 500));

    this.countUpNum1();
    this.countUpNum2();
  },
  methods: {
    async countUpNum1() {
      do {
        const factor = 1 - this.number1 / this.number1Max;

        this.number1 += Math.max(70 * factor, 0.03);
        await new Promise(resolve => setTimeout(resolve, 45));
      } while (this.number1 < this.number1Max);
    },
    async countUpNum2() {
      do {
        const factor = (1 - this.number2 / this.number2Max) / 2;

        this.number2 += Math.max(70 * factor, 0.03);
        await new Promise(resolve => setTimeout(resolve, 45));
      } while (this.number2 < this.number2Max);
    }
  }
};
</script>

<style lang="scss" scoped>
.learnMore {
  z-index: 10;
  @media (min-width: 850px) {
    padding: 0px 0 40px 0;
  }
  padding: 30px 0 50px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  grid-row: 4 / 4;
  grid-column: 1 / 4;
}

@media (min-width: 800px) {
  .content-container {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    flex-wrap: wrap;
    .battle-box {
      display: grid;
      width: auto;
      height: 100%;
      grid-template-columns: 3fr auto 3fr;
      grid-template-rows: 3fr 3fr 1fr;
      justify-items: center;
      align-self: center;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 15px 0 50px rgba(142, 187, 255, 0.5), -15px 0 50px rgba(142, 187, 255, 0.5);
      #left-challenge-box {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
      #right-challenge-box {
        grid-column: 3 / span 1;
        grid-row: 2 / 3;
      }
      .challenge-box {
        z-index: 1;
        grid-row: 2 / 3;
        min-width: 44vh;
        justify-self: center;
        align-self: center;
        padding-bottom: 2em;
        .img-box {
          display: grid;
          position: relative;
          grid-template: 1fr / 1fr;
          .challenge-img {
            z-index: 1;
            width: 30vh;
            height: 60vh;
            object-fit: cover;
            border-radius: 30px;
            border: double 5px transparent;
            background-image: linear-gradient(white, white),
              linear-gradient(
                140deg,
                rgb(255, 233, 5),
                rgb(255, 46, 9),
                rgb(255, 233, 5),
                rgb(255, 46, 9)
              );
            background-origin: border-box;
            background-clip: content-box, border-box;

            justify-self: center;
            align-self: center;
          }
        }
      }

      .moderator {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        width: 10em;
        align-self: end;
        justify-self: end;
        margin-bottom: -5em;
      }
      .swords {
        z-index: 10;
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        padding-bottom: 25px;
        width: 7em;
        margin: auto;
      }
      .footer-text {
        z-index: 10;
        grid-column: 1 / -1;
        align-self: start;
        justify-self: center;
        font-size: 2.2rem;
        font-weight: bold;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
      }
      .number-left {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
      }
      .number-right {
        grid-row: 1 / span 1;
        grid-column: 3 / span 1;
      }
      .number {
        max-height: 50px;
        margin-top: 2em;
        margin-bottom: 0.5rem;
        font-size: 2.5rem;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 2px;
        border-radius: 25px;
        border-width: 4px;
        border-style: solid;
        border-color: rgb(255, 119, 8);
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
      }
    }
  }

  //////////////////////////////////////

  .background-icons-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;

    .background-icons-1-img {
      opacity: 0.8;
      transform: scale(1.3);
    }
  }

  .background-icons-2 {
    position: absolute;
    bottom: 15vh;
    left: 0;
    width: 100vw;
    opacity: 0.4;
    z-index: -2;
    transform: scale(1.5);
  }

  .background-icons-3 {
    position: absolute;
    bottom: 50vh;
    left: 0;
    width: 100vw;
    opacity: 0.2;
    z-index: -3;
  }
}

@media (min-width: 550px) and (max-width: 800px) {
  .full-page {
    display: grid;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: end;
    width: 100%;
    height: 90vh;
    .battle-box {
      display: grid;
      width: 80%;
      height: 100%;
      grid-template-columns: 3fr auto 3fr;
      grid-template-rows: 1fr 3fr 1fr;
      justify-items: center;
      align-self: center;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 15px 0 50px rgba(142, 187, 255, 0.5), -15px 0 50px rgba(142, 187, 255, 0.5);

      #left-challenge-box {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
      }
      #right-challenge-box {
        display: none;
        grid-column: 1 / -1;
        grid-row: 2 / 3;
      }
      .challenge-box {
        z-index: 1;
        grid-row: 2 / 3;
        justify-self: center;
        align-self: center;
        .img-box {
          display: grid;
          position: relative;
          grid-template: 1fr / 1fr;
          .challenge-img {
            z-index: 1;
            width: 35vh;
            object-fit: cover;
            border-radius: 50px;
            border: double 5px transparent;
            background-image: linear-gradient(white, white),
              linear-gradient(
                140deg,
                rgb(255, 233, 5),
                rgb(255, 46, 9),
                rgb(255, 233, 5),
                rgb(255, 46, 9)
              );
            background-origin: border-box;
            background-clip: content-box, border-box;

            justify-self: center;
            align-self: center;
          }
        }
      }
      .moderator {
        display: none;
      }
      .swords {
        display: none;
      }
      .footer-text {
        z-index: 10;
        grid-column: 1 / -1;
        align-self: start;
        justify-self: center;
        font-size: 1.9rem;
        font-weight: bold;
        width: 80%;
        padding-top: 1rem;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .number-left {
        grid-row: 1 / span 1;
        grid-column: 1 / -1;
        justify-self: center;
        align-self: end;
        padding-bottom: 10px;
        font-size: 2.5rem;
        font-weight: bold;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .number-right {
        display: none;
      }
    }
  }

  //////////////////////////////////////

  .background-icons-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;

    .background-icons-1-img {
      opacity: 0.3;
      transform: scale(1.3);
    }
  }

  .background-icons-2 {
    position: absolute;
    left: 0;
    bottom: 50%;
    height: 50vh;
    width: 100vw;
    opacity: 0.4;
    z-index: -2;
    transform: scale(3);
  }

  .background-icons-3 {
    display: none !important;
  }
}

//Phone viewport

@media (max-width: 550px) {
  .full-page {
    display: grid;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-self: center;
    padding-top: 3rem;
    overflow: hidden;
    .battle-box {
      display: grid;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      justify-content: center;

      #right-challenge-box {
        display: none;
        position: absolute;
        .challenge-img {
          height: 172.5%;
        }
      }
      .challenge-box {
        z-index: 1;
        justify-self: center;
        align-self: center;
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        width: 100%;
        .img-box {
          display: grid;
          position: relative;
          grid-template: 1fr / 1fr;
          object-fit: cover;
          .challenge-img {
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .moderator {
        z-index: 10;
        display: none;
        justify-self: end;
        padding-top: 4.5em;
        grid-column: 2 / span 1;
        grid-row: 1 / 1;
        width: 50%;
        margin-top: 15%;
        transform: scale(0.75);
      }
      .swords {
        display: none;
        position: absolute;
        padding-top: 50px;
        width: 6em;
        margin: auto;
      }
      .footer-text {
        z-index: 10;
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        align-self: center;
        justify-self: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 1em;
        font-size: 2.25rem;
        font-weight: bold;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: white;
      }
      .number-left {
        display: none;
        z-index: 10;
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
        justify-self: end;
        align-self: end;
        margin-top: 0.65em;
        padding-right: 0.15em;
        font-size: 2.5rem;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .number-right {
        display: none;
        grid-row: 1 / span 1;
        grid-column: 3 / span 1;
        justify-self: center;
        align-self: center;
        margin-top: 0.75em;
        padding-bottom: 3vh;
        font-size: 3rem;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  //////////////////////////////////////

  .background-icons-1 {
    visibility: hidden !important;
    display: none !important;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;

    .background-icons-1-img {
      display: none !important;
      visibility: hidden !important;
      opacity: 0.3;
      transform: scale(1.3);
    }
  }

  .background-icons-2 {
    visibility: hidden !important;
    display: none !important;
    position: absolute;
    left: 0;
    width: 100vw;
    opacity: 0.4;
    z-index: -2;
    transform: scale(1.5);
  }

  .background-icons-3 {
    visibility: hidden !important;
    display: none !important;
    position: absolute;
    left: 0;
    width: 100vw;
    opacity: 0.2;
    z-index: -3;
  }
}
</style>
