import { createApp } from "vue";
import App from "./App.vue";

import Utils from "./utils.js";

import "./assets/global.scss";

import router from "./router";

const app = createApp(App);

app.config.globalProperties.$utils = Utils;

app.use(router);

app.mount("#app");
