<template>
  <div class="full-page">
    <go-back-btn :hash="'#challenges'" />
    <img class="background-icons" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />

    <div class="content-container">
      <div class="description-box">
        <div class="description-card">
          <div class="description-header">
            Battles
          </div>
          <div class="description-card-content">
            <img
              class="description-card-img"
              src="@/assets/img/BattlesAndChallenges/SwordsBattlesIcon.svg"
            />
            <div class="description-card-text">
                Challenge another in your niche Talent to a battle and prove your superiority. The
                winner awaits glamour and glory in form of Talentum and Talent votes to further
                climb the leaderboard.
            </div>
          </div>
        </div>
        <div class="description-card">
          <div class="description-header">
            Challenges
          </div>
          <div class="description-card-content">
            <img
              class="description-card-img"
              src="@/assets/img/BattlesAndChallenges/LightningChallengesIcon.svg"
            />
            <div class="description-card-text">
                There is no better way to improve your Talent and collaborate with new like-minded
                people than to compete in challenges. Participate in Challenges to exceed your limits 
                or enrich the community by creating your own Challenges other Talents can join (1:n).  
                Participants earn points that will propell them towards the top of the leaderboard. 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from "../../components/GoBackBtn.vue";

export default {
  name: "LeaderboardLearnMore",
  components: {
    GoBackBtn
  }
};
</script>

<style lang="scss" scoped>
.full-page{
  display: grid;
}
.content-container {
  @media (max-width: 975px) {
    padding: 120px 25px 0 25px;
    width: calc(100vw - 50px);
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  padding-top: 6rem;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 80%;
  height: 75%;

  .description-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8vh;

    .description-card {
      .description-header {
        @media (max-width: 975px) {
          text-align: center;
          font-size: 45px;
        }
        text-align: left;
        font-size: min(5.5vh, 8vw);
        font-weight: bold;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description-card-content {
        margin: 20px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        flex-direction: row;
        gap: 25px;

        .description-card-img {
          margin: 0 25px 0 25px;
          @media (max-width: 975px) {
            height: 150px;
            width: 150px;
          }
          @media (min-width: 975px) {
            border-right: 5px solid darkorange;
            padding-right: 2em; 
          }
          width: 10vw;
          min-width: 130px;
        }
        .description-card-text {
          display: flex;
          gap: 45px;
          max-width: 40vw;
          font-size: min(2.5vh, 2.5vw);
          @media (max-width: 975px) {
            font-size: 22px;
            max-width: 80vw;
            border-top: 5px solid darkorange;
            padding-top: 1em;
            text-align: center;
          }
          text-align: left;
          padding: 0;
          margin: 0;
          align-self: center;
        }
      }
    }
  }
}

.background-icons {
  z-index: -5;
  position: absolute;
  opacity: 0.05;
  left: 0;
  bottom: 0;
  height: 3000px;
  user-select: none;
}
</style>
