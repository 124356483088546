export default class Utils {
  static formatDate(ts) {
    if (!ts) {
      return '';
    }
    
    const date = new Date(ts);

    let day = String(date.getDate());
    let month = String(date.getMonth() + 1);
    let year = date.getFullYear();

    day = day.length === 1 ? "0" + day : day;
    month = month.length === 1 ? "0" + month : month;

    return day + "." + month + "." + year;
  }

  static formatTime(ts) {
    if (!ts) {
      return '';
    }
    
    const date = new Date(ts);

    let minutes = String(date.getMinutes());
    let hours = String(date.getHours());

    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    hours = hours.length === 1 ? "0" + hours : hours;
    return hours + ":" + minutes;
  }
}
