<template>
  <div class="container">
    <img class="background-triangles" src="@/assets/img/theLeaderboard/TopTriangles.svg" />
    <img class="background-sun" src="@/assets/img/theLeaderboard/Sun.svg" />
    <div class="flex-box">
      <div class="text-box">
        <div class="text-header">Become An Ambassador</div>
        <p class="text-description">
          At Talentsverse, we're always thrilled to meet new potential partners! If you're an
          influencer interested in exploring unique partnership opportunities with us, we're more
          than happy to arrange a meeting to discuss the potential benefits.
          <br />
          <br />
          In order for us to get to know you better and tailor the benefits according to your needs,
          please fill out the form below. Your input is valuable to us, and we assure you that all
          information will be kept confidential.
          <br />
          <br />
          At Talentsverse, we believe in building strong partnerships that allow our collaborators
          to maximize their potential. Whether it's through increased exposure, sponsorships, or
          personalized support, we're committed to helping you succeed as an influencer in your
          niche. Once we receive your form, we will be in touch to arrange a meeting at a time that
          is convenient for you. In the meantime, if you have any queries, feel free to email us at
          <a href="mailto:partnership@talentsverse.com">partnership@talentsverse.com</a>
          <br />
          <br />
          Thank you for your interest in partnering with Talentsverse. We're looking forward to
          discussing the exciting possibilities of our collaboration!
          <br />
          <br />
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc7PyFvMG38l5ZmIT7VggxEItGcYnkXlUtcJwuvn41mXSE48w/viewform?usp=sf_link"
            >Ambassador Google Form</a
          >
          <br />
          <br />
          The Talentsverse Team
        </p>
      </div>
      <div>
        <img class="background-paper-plane" src="@/assets/img/theLeaderboard/PaperPlane.svg" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
  }
  padding: 200px 0 0 0;
  width: 100vw;
  height: calc(100vh - 100px);
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 1rem;
}

.background-sun {
  z-index: -5;
  position: absolute;
  height: 30vh;
  left: 5vw;
  top: 20vh;
  animation: spin 100s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.background-triangles {
  display: block;
  position: absolute;
  bottom: -100px;
  transform: rotate(180deg);
  width: 100vw;
}

.background-paper-plane {
  z-index: 100;
  @media (min-width: 900px) {
    width: 20rem;
    display: block;
  }
  transform: rotate(-60deg) scale(2);
  display: none;
}

.flex-box {
  @media (min-width: 900px) {
    flex-direction: row;
    gap: 4rem;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 2rem;
}

.text-box {
  padding: 0rem 1rem;
  padding-bottom: 10rem;
  max-width: 900px;
}

.text-header {
  @media (min-width: 1200px) {
    font-size: 5rem;
  }
  @media (min-width: 900px) {
    font-size: 4rem;
  }
  font-weight: bold;
  font-size: 3rem;
  background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-description {
  @media (min-width: 1400px) {
    font-size: 1.5rem;
  }
  @media (min-width: 900px) {
    font-size: 1.4rem;
  }
  font-size: 1rem;
  line-height: 1.5;
}
</style>
