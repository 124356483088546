<template>
  <div class="full-page">
    <go-back-btn :hash="'#leaderboard'" />
    <img class="background-stock-line" src="@/assets/img/theLeaderboard/learnMore/StockLine.svg" />

    <div class="content-container">
      <div class="description-box">
        <div class="description-card">
          <div class="description-header">
            Scholarships!
          </div>
          <div class="description-card-content">
            <img
              class="description-card-img"
              src="@/assets/img/theLeaderboard/learnMore/Scholarship.svg"
            />
            <div class="description-card-text">
              <div class="divider" />
              <div class="text">
                Impress the community with your Talent and they will praise you with their precious
                Talentum! The more Talentum you collect, the higher you will rank in the global
                leaderboard. Only the best among the best will reach the top and will receive a
                scholarship from us for up to 12 months! That means, every month we pay you with
                Talentum.
              </div>
            </div>
          </div>
        </div>
        <div class="description-card">
          <div class="description-header">
            Talentum...?
          </div>
          <div class="description-card-content">
            <img
              class="description-card-img"
              src="@/assets/img/theLeaderboard/learnMore/TalentumCoin.svg"
            />
            <div class="description-card-text">
              <div class="divider" />
              <div class="text">
                Talentum is our free tradeable Crypto Currency. With it, you can vote for talents,
                purchase items from our shops, sell it on the crpytomarket for cash or, you know,
                just hold it for a while...

                <div style="height: 1em" />
                <a href="www.google.de"> Learn even more about Talentum</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="img-box">
        <div class="phone-frame">
          <img class="img-frame" src="@/assets/img/theLeaderboard/learnMore/AppLeaderboard.png" />
        </div>
        <div class="img-subtitle">
          Interested?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from "../../components/GoBackBtn.vue";

export default {
  name: "LeaderboardLearnMore",
  components: {
    GoBackBtn
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 975px) {
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
  }
  padding: 140px 100px 0 100px;
  width: calc(100vw - 200px);
  height: calc(100vh - 140px);
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 1vw;

  .description-box {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    @media (max-width: 1000px) {
      gap: 70px;
    }
    min-width: 300px;

    .description-card {
      .description-header {
        @media (max-width: 975px) {
          text-align: center;
        }
        text-align: left;
        font-size: max(2vw, 35px);
        font-weight: bold;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description-card-content {
        margin: 20px 0 0 0;
        display: flex;
        justify-content: center;
        flex-direction: row;
        @media (max-width: 975px) {
          flex-direction: column;
        }
        flex-wrap: wrap;
        gap: 25px;

        .description-card-img {
          @media (max-width: 975px) {
            margin: auto;
          }
          margin: 0 25px 0 25px;
          width: 11vw;
          min-width: 130px;
        }

        .description-card-text {
          display: flex;
          gap: 45px;
          max-width: 25vw;
          min-width: 275px;
          font-size: 1.2vw;
          @media (max-width: 1000px) {
            font-size: 22px;
          }
          text-align: left;

          .text {
            margin: auto;
          }

          .divider {
            height: 100%;
            width: 20px !important;
            background-image: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
          }
        }
      }
    }
  }

  .img-box {
    padding-bottom: 50px;
    .img-frame {
      width: 15vw;
      min-width: 200px;
      @media (max-width: 1000px) {
        margin-top: 100px;
      }
    }

    .img-subtitle {
      margin-top: 25px;
      font-size: max(4vw, 35px);
      font-weight: bold;
      background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

////////////////////////////////////

.background-stock-line {
  z-index: -1;
  position: absolute;
  width: 100vw;
  bottom: 0;
  left: 0;
}
</style>
