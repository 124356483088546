<template>
  <div class="full-page">
    <go-back-btn :hash="'#profile'" />
    <img class="background-stars" src="@/assets/img/profile/BackgroundStars.svg" />

    <div class="content-container">
      <div class="description-card">
        <div class="description-card-content">
          <div class="description-header">
            Introduce Yourself
          </div>
          <div class="description-card-text">
            <div class="text">
              Tell people about who you are and what you are about. Customize your profile to
              attract your target audience and find similiar Talents that fit your description.
              Share information to an extend you will feel comfortable with, and with which
              community members can paint a picture of you. Showing who you are stands in high
              interests of clients that want to collaborate with you.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/profile/learnMore/SunIcon.svg" />
        </div>
      </div>

      <div class="description-card">
        <div class="description-card-content">
          <div class="description-header">
            Curate Your Profile
          </div>
          <div class="description-card-text">
            <div class="text">
              It is important to carefully arrange your content uploads on your profile. Potential
              clients may only take a few seconds to look through your profile page. Impress them if
              you want them to hire you for a gig, projects, sponsoring and so on.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/profile/learnMore/ImagesIcon.svg" />
        </div>
      </div>

      <div class="description-card">
        <div class="description-card-content">
          <div class="description-header">
            News
          </div>
          <div class="description-card-text">
            <div class="text">
              Keep your community informed about your latest achievements and upcoming events. Share
              short snippets of projects you are working on in your profile and exchange short
              messages with your fanbase in seconds.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/profile/learnMore/Newspaper.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from "../../components/GoBackBtn.vue";

export default {
  name: "LeaderboardLearnMore",
  components: {
    GoBackBtn
  },
  mounted() {
    // fix weird scrolling of app element
    document.getElementById("app").scroll({ top: 0 });
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 110px 25px 0 25px;
    width: calc(100vw - 50px);
    height: calc(100vh - 110px);
  }
  padding: 180px 75px 0 75px;
  width: calc(100vw - 150px);
  height: calc(100vh - 180px);
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 2em 5em;

  .description-card {
    max-width: 350px;
    padding-bottom: 50px;

    .description-card-content {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1em;
      gap: 15px;

      .description-header {
        text-align: left;
        font-size: min(5vh, 5vw);
        @media (max-width: 1000px) {
          font-size: 50px;
        }
        font-weight: bold;
        min-height: 2.4em;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description-card-img {
        margin: auto;
        height: 200px;
        width: 200px;
      }

      .description-card-text {
        display: flex;
        gap: 45px;
        max-width: 500px;
        min-height: 11em;
        font-size: min(2.3vh, 2.3vw);
        @media (max-width: 1000px) {
          font-size: 22px;
        }
        text-align: left;

        .text {
          margin: auto;
        }
      }

      .divider {
        height: 5px;
        width: 100%;
        background-image: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
      }
    }
  }
}

.background-stars {
  z-index: -3;
  height: 100vh;
  width: 100vh;
  position: absolute;
  bottom: 0;
  transform: rotate(-90deg);
  right: 0;
  opacity: 0.8;
  filter: blur(8px);
}
</style>
