<template>
  <div class="full-page">
    <go-back-btn :hash="'#fanbase'" />
    <img class="background-ConnectedDots" src="@/assets/img/fanbase/ConnectedDots.svg" />

    <div class="content-container">
      <div class="description-card card-1">
        <div class="description-card-content">
          <div class="description-header">
            Make Connections
          </div>
          <div class="description-card-text">
            <div class="text">
              Create an engaged community that will cheer for you on your journey to a more talented
              self. Find new friends that share and complement your interests, build new
              partnerships and an engaged fanbase.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/fanbase/learnMore/Dots.svg" />
        </div>
      </div>

      <div class="description-card card-2">
        <div class="description-card-content">
          <div class="description-header">
            Sell Merchandise
          </div>
          <div class="description-card-text">
            <div class="text">
              Speaking of fanbases... Fans would not be fans without merchandise. Provide your
              Fanbase with merchandise articles you can sell in your shop on our platform.
              Respectively support your favourite Talent by purchasing their articles.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/fanbase/learnMore/Merch.svg" />
        </div>
      </div>

      <div class="description-card card-3">
        <div class="description-card-content">
          <div class="description-header">
            Create Courses
          </div>
          <div class="description-card-text">
            <div class="text">
              Create video courses which pair your personality with your expertise as a Talent, and
              share them with the community.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/fanbase/learnMore/Course.svg" />
        </div>
      </div>

      <div class="description-card card-4">
        <div class="description-card-content">
          <div class="description-header">
            Go <br/>Live
          </div>
          <div class="description-card-text">
            <div class="text">
              Get closer to the community by arranging live shows. Live shows are scheduled events
              anyone who purchased a ticket for a particular live show can attend. Receive donations
              in form of talentum.
            </div>
          </div>
          <div class="divider" />
          <img class="description-card-img" src="@/assets/img/fanbase/learnMore/Stream.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from "../../components/GoBackBtn.vue";

export default {
  name: "LeaderboardLearnMore",
  components: {
    GoBackBtn
  },
  mounted() {
    // fix weird scrolling of app element
    document.getElementById("app").scroll({ top: 0 });
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
  @media (max-width: 600px) {
    padding: 110px 25px 0 25px;
    width: calc(100vw - 50px);
    height: calc(100vh - 110px);
  }
  padding: 110px 50px 0 100px;
  width: calc(100vw - 150px);
  height: calc(100vh - 140px);
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 0.5em 2em;
  margin: auto;

  .description-card {
    max-width: 275px;
    min-width: 225px;
    padding-bottom: 25px;

    .description-card-content {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1em;
      margin-bottom: 1em;
      gap: 15px;

      .description-header {
        text-align: left;
        font-size: min(5vh, 5vw);
        @media (max-width: 1000px) {
          font-size: 35px;
        }
        font-weight: bold;
        min-height: 2.4em;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description-card-img {
        padding-top: 20px;
        margin: auto;
        height: 175px;
        width: 175px;
      }

      .description-card-text {
        display: flex;
        gap: 45px;
        max-width: 500px;
        min-width: 250px;
        min-height: 13em;
        font-size: min(2.6vh, 2.6vw);
        border-bottom: 5px solid darkorange;
        @media (max-width: 1000px) {
          font-size: 20px;
        }
        text-align: left;
      }
    }
  }
}

.background-ConnectedDots {
  position: absolute;
  transform: rotate(180deg);
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
  opacity: 0.75;
  filter: blur(12px);
}
</style>
