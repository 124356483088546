<template>
  <div class="full-page">
    <go-back-btn :hash="'#marketplace'" />
    <div class="content-container">
      <div class="description-card">
        <div class="description-card-content">
          <div class="description-header">
            NFT Marketplace
          </div>
          <div class="content-box">
            <div class="description-card-text">
              <div class="text">
                Our Crypto-currency, the Talentum Token, forms the perfect basis to broaden our
                possibilties as a company. The ever growing market for NFTs (Non fungible Tokens)
                and our enthusiasm regarding them, has let us to work on our own NFT Marketplace,
                which will be fully integrated and work in conjungtion with the Talents application.
                Our plan is to make NFTs more accessible to everybody. To Achieve this, users of
                Talents can directly convert their content uploads from Talents to NFTs and sell
                them in their shop on our Marketplace. NFTs then can be purchased by anyone via the
                Talentum Token. This project stands at the beginning of development, any updates and
                its current status will be displayed here.
              </div>
            </div>
            <img class="nft-marketplace" src="@/assets/img/upcomingProject/NFTMarketplace.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBackBtn from "../../components/GoBackBtn.vue";

export default {
  name: "UpcomingProjectLearnMore",
  components: {
    GoBackBtn
  },
  mounted() {
    // fix weird scrolling of app element
    document.getElementById("app").scroll({ top: 0 });
  }
};
</script>

<style lang="scss" scoped>
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: 0;
  @media (max-width: 600px) {
    padding: 110px 25px 0 25px;
    width: calc(100vw - 50px);
    height: calc(100vh - 110px);
  }
  padding: 200px 100px 0 100px;
  width: calc(100vw - 200px);
  height: calc(100vh - 200px);
  justify-content: space-around;
  flex-direction: column;
  @media (max-width: 1000px) {
    flex-direction: row;
  }
  display: flex;
  gap: 1em 1em;

  .nft-marketplace {
    align-self: center;
    width: 40%;
    min-width: 350px;
    max-width: 1000px;
    border: 5px solid orange;
  }

  .description-card {
    padding-bottom: 25px;

    .description-card-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 1em;
      margin-bottom: 1em;
      gap: 15px;

      .content-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 2rem;
      }

      .description-header {
        text-align: left;
        font-size: min(5vh, 5vw);
        @media (max-width: 1000px) {
          font-size: 50px;
        }
        font-weight: bold;
        min-height: 2.4em;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .description-card-text {
        display: flex;
        flex-direction: row;
        max-width: 500px;
        min-height: 11em;
        font-size: min(2.5vh, 2.5vw);
        @media (max-width: 1000px) {
          font-size: 22px;
          border-bottom: 4px solid orange;
          padding-bottom: 35px;
        }
        @media (min-width: 1000px) {
          border-right: 4px solid orange;
          padding-right: 35px;
        }
        text-align: left;

        .text {
          margin: auto;
        }
      }
    }
  }
}
</style>
