<template>
  <div class="full-page">
    <img class="background-ConnectedDots" src="@/assets/img/fanbase/ConnectedDots.svg" />

    <div class="content-container">
      <div class="header-text">
        Generate your promocode
      </div>
      <div>
        <div class="input-text">
          Your Full Name
        </div>
        <input v-model="name" maxlength="48" />
      </div>

      <div>
        <div class="input-text">
          Your Email
        </div>
        <input v-model="email" maxlength="64" />
      </div>

      <div v-if="code !== ''" style="margin-top: 3rem;">
        <div class="input-text">
          Your promocode:
        </div>
        <div class="invite-code">
          {{  code }}
        </div>
      </div>

      <div v-if="code == ''" class="submit-btn" @click="submitWaitlist">
        Generate my code
      </div>
    </div>
  </div>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";

export default {
  name: "Waitlist",
  data() {
    return {
      database: null,
      name: null,
      email: null,
      code: "",
    };
  },
  created() {
    const firebaseConfig = {
      databaseURL: "https://talents-website-e8e0e-default-rtdb.europe-west1.firebasedatabase.app/"
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);
  },
  methods: {
    submitWaitlist() {
      if (!this.name || this.name.length < 0) {
        return alert("Name is required");
      }
      
      if (this.name.length < 4) {
        return alert("Name is to short");
      }

      if (!this.email || this.email.length < 0) {
        return alert("Email is required");
      }

      if (
        !this.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return alert("Must be a valid email");
      }

      const nameFirstChars = this.name.slice(0, 4).toUpperCase();

      this.code = nameFirstChars + Math.round(Math.random() * 10000);

      set(ref(getDatabase(), "/invitation/" + Math.round(Math.random() * 100000000000000000)), {
        name: this.name,
        email: this.email,
        code: this.code
      });

      alert("Your code has been created and saved.");
    }
  }
};
</script>

<style lang="scss" scoped>
.input-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
  background-clip: text;
  -webkit-text-fill-color: transparent;

  .small {
    font-size: 1.25rem;
  }

  .xs {
    font-size: 0.9rem;
  }
}

.side-input {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.submit-btn {
  width: 250px;
  margin: auto;
  padding: 10px;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
}

.invite-code {
  font-size: 4rem;
}

input {
  width: 80%;
  padding: 16px 20px;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #f1f1f1;
}

@media (min-width: 900px) {
  .content-container {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 130px 150px 75px 75px;
    width: calc(100vw - 225px);
    box-shadow: 0px 0px 2vw rgba(142, 187, 255, 0.7);
    background-color: rgb(255, 255, 255, 0.9);
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    flex-wrap: wrap;
    display: flex;
    z-index: 0;

    .header-text {
      max-width: 45vw;
      font-size: 4em;
      font-weight: bold;
      background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 900px) {
  .content-container {
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
    box-shadow: 0px 0px 2vw rgba(142, 187, 255, 0.7);
    background-color: rgb(255, 255, 255, 0.9);
    align-content: center;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
    display: flex;
    z-index: 0;

    .header-text {
      width: 95%;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
      background: linear-gradient(90deg, rgb(255, 164, 57), rgb(255, 28, 80));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.background-ConnectedDots {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: -5;
  @media (max-width: 1080px) {
    filter: blur(2px);
  }
}
</style>
