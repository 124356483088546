<template>
  <div class="nav-container" :class="whiteBackground ? 'white-back' : ''">
    <img
      src="@/assets/img/logos/TalentsverseLogo.svg"
      @click="$router.push('/')"
      alt="star"
      class="talents-logo"
    />
    <div class="navbar-btns">
      <div class="navbar-btn-text glow-on-hover" @click="$router.push('/blog')">
        Blog
      </div>
      <div class="navbar-btn-text glow-on-hover dropdown">
        Social Media
        <div class="dropdown-links">
          <a href="https://t.me/Talentsverse" target="_blank" class="glow-on-hover">Telegram</a>
          <a href="https://twitter.com/GetTalentsverse" target="_blank" class="glow-on-hover"
            >Twitter</a
          >
          <a href="https://discord.gg/P9Rgek4bpC" target="_blank" class="glow-on-hover">Discord</a>
        </div>
      </div>
      <div class="navbar-btn-text glow-on-hover dropdown">
        Talentsverse
        <div class="dropdown-links">
          <!-- <a href="https://talentum.capital/" class="glow-on-hover">Talentum</a> -->
          <a href="https://talents-course.web.app/" target="_blank" class="glow-on-hover"
            >Courses</a
          >
          <a href="https://opensea.io/Talinhos" target="_blank" class="glow-on-hover">NFTs</a>
          <a href="/invitation-code-generator" target="_blank" class="glow-on-hover">Promocode</a>
          <a href="/ambassador" class="glow-on-hover">Ambassador</a>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/talents-website-e8e0e.appspot.com/o/TalentsWhitepaper.pdf?alt=media&token=8968cfb6-7f16-4aca-8fbe-201a722efdbf"
            target="_blank"
            class="glow-on-hover"
            >White Paper</a
          >
        </div>
      </div>
      <div v-if="registerBtn" class="navbar-btn-text register-btn">
        Register
      </div>
    </div>
  </div>

  <div class="hamburger-menu">
    <img
      src="@/assets/img/logos/TalentsverseLogo.svg"
      @click="$router.push('/')"
      alt="star"
      class="talents-logo-right"
    />
    <nav role="navigation">
      <div id="menuToggle">
        <!--
    A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it.
    -->
        <input type="checkbox" />

        <!--
    Some spans to act as a hamburger.
      -->
        <span></span>
        <span></span>
        <span></span>

        <!--
    Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic.
    -->
        <ul id="menu">
          <div class="header"><li>App</li></div>
          <a href="/blog"><li>Blog</li></a>
          <div class="header padding"><li>Social Media</li></div>
          <a href="https://t.me/Talentsverse" target="_blank"
            ><li>Telegram</li></a
          >
          <a href="https://twitter.com/GetTalentsverse" target="_blank"
            ><li>Twitter</li></a
          >
          <a href="https://discord.gg/P9Rgek4bpC" target="_blank"
            ><li>Discord</li></a
          >
          

          <div class="header padding"><li>Talentsverse</li></div>
          <!-- <a href="https://talentum.capital/"><li>Talentum</li></a> -->
          <a href="https://talents-course.web.app/"><li>Courses</li></a>
          <a href="https://opensea.io/Talinhos"><li>NFTs</li></a>
          <a href="/invitation-code-generator" ><li>Promocode</li></a>
          <a href="/ambassador" ><li>Ambassador</li></a>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/talents-website-e8e0e.appspot.com/o/TalentsWhitepaper.pdf?alt=media&token=8968cfb6-7f16-4aca-8fbe-201a722efdbf"
            target="_blank"
            ><li>
              White Paper
            </li>
          </a>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      whiteBackground: false
    };
  },
  props: {
    registerBtn: {
      default: false,
      Boolean
    }
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    }
  },
  watch: {
    $route(to) {
      if (to.fullPath.includes("learn-more")) {
        this.whiteBackground = true;
      } else {
        this.whiteBackground = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

@media (min-width: 1000px) {
  .hamburger-menu {
    display: none;
  }

  .nav-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &.white-back {
      background-color: #ffffff;
      box-shadow: 10px 0px 50px rgba(142, 187, 255, 0.5);
    }
  }

  .talents-logo {
    cursor: pointer;
    height: 3vw;
    margin: 0 0 0 5%;
    background: rgba(#ffffff, 0.75);
    border-radius: 15px;
    box-shadow: 0 0 5px 10px rgba(#ffffff, 0.75);
  }

  .navbar-btns {
    margin: 0 100px 0 0;
    align-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 50px;
  }

  .navbar-btn-text {
    font-family: "Noto Sans", sans-serif;
    font-size: 1.125vw;
    font-weight: bold;
  }

  .register-btn {
    font-size: 1.325vw;
    font-family: "Noto Sans", sans-serif;
    color: white;
    background-color: black;
    border-radius: 50px;
    padding: 15px 25px;
    animation: grow-shrink 4s ease-in-out infinite;
  }

  @keyframes grow-shrink {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .register-btn:hover {
    //animation: pulse 1s ease-in-out infinite alternate;
    box-shadow: 15px 0 50px rgba(142, 187, 255, 1), -15px 0 50px rgba(142, 187, 255, 1);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0px 0px 0px rgba(142, 187, 255, 0.5), -15px 0 50px rgba(142, 187, 255, 0.5);
    }
    100% {
      box-shadow: 15px 0 10px rgba(142, 187, 255, 1), -15px 0 50px rgba(142, 187, 255, 1);
    }
  }
}

@media (max-width: 999px) {
  .nav-container {
    display: none;
  }

  .hamburger-menu {
    background-color: #ffffff;
    box-shadow: 10px 0px 50px rgba(142, 187, 255, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    display: flex;
    flex-direction: row-reverse;
    width: 100vw;
    justify-content: space-between;

    .talents-logo-right {
      height: 50px;
      margin: 15px;
    }
  }

  #menuToggle {
    display: block;
    position: relative;
    top: 35px;
    left: 35px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle .header {
    font-size: 22px !important;
    font-weight: bold;

    &.padding {
      padding-top: 1em;
    }
  }

  #menuToggle a:hover {
    color: rgb(255, 145, 0);
    text-decoration: underline;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 35px;
    height: 5px;
    margin-bottom: 5px;
    position: relative;

    background: #2c2c2c;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 175px;
    height: 100vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    background: #ffffff;
    opacity: 0.98;
    box-shadow: 10px 0px 50px rgba(0, 0, 0, 0.7);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 7px 0;
    font-size: 20px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: none;
  }
}
.dropdown {
  position: relative;
}

.dropdown .dropdown-links {
  display: none;
}

.dropdown:hover .dropdown-links {
  box-shadow: 0px 0px 20px #ff8800b2;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  padding: 15px 5px 15px 5px;

  a {
    font-size: 0.9em;
    color: #000;
    text-decoration: none;
  }
}

////////////////////////////////////////

.glow-on-hover {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  padding: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #ff0000);
  position: absolute;
  top: -4px;
  left: -4px;
  background-size: 500%;
  z-index: -1;
  filter: blur(15px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 40s linear infinite;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 0.35;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 500% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
