<template>
  <navbar :registerBtn="false" />
  <release-modal v-if="showModal" @close="showModal = false" />
  <router-view />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import ReleaseModal from "./components/ReleaseModal.vue";

export default {
  components: { Navbar, ReleaseModal },
  name: "App",
  data() {
    return {
      showModal: true
    };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

img {
  user-select: none;
}

</style>
