<template>
  <div>
    <img class="background-icons" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />
    <div class="content-container">
      <h1 class="header">
        Terms and Conditions
      </h1>

      <div class="text">
        Welcome to Talents!<br /><br />
        These Terms of Use govern your use of Talents and provide information about the Talents
        service explained below. By creating a Talents account or using Talents, you agree to these
        Terms of Use.
        <br /><br />The Talents Service<br /><br />
        Our services provide you with the ability to create content, connect and communicate,
        discover and share. And it's all about your talent. We want to help you do this, for example
        by working with scouts and agencies together. We develop systems that help us find out who
        can support you on your way and bring you together.<br /><br />
        We then use the data and information we receive from you to increase your reach, for example
        by sharing your interest with cooperation partners. Through our unique radius function, you
        can get in touch faster and better with agencies and scouts in your area. We connect you
        with brands, products and services that are important to you. Of course, we use all the data
        available to us to continuously improve our service for you. What is very important to us is
        the protection of your personal data.
        <br /><br />
        The privacy policy What data we collect and how we process it can be found in our current
        privacy policy, in which we describe exactly what data we collect, why and for what purpose
        we process it, who can view the data and what your rights are.
        <br /><br />
        Your obligations<br /><br />
        There are only a few simple rules to follow when using our service:<br />
        - You must be at least 12 years old.<br />
        - You must not be prohibited by applicable law from receiving any aspect of our service or
        from engaging in payment-related services if you are on an applicable denied party list.<br />
        - We must not have previously disabled your account for a violation of our policies.<br />
        - You must not be a convicted sex offender.<br />
        - You must not be trading under someone else's identity. You do not have to disclose your
        identity on Talents, but you must provide us with your real information or you cannot be
        part of Talents.<br />
        - Everyone is only allowed to have one account.<br />
        <br /><br />
        For scouts and agencies<br /><br />
        For scouts and agencies the same rules apply as for our talents, but there are some
        additional agreements for using our service:<br />
        - Talent found and recruited through our service may only be recruited through our
        service.<br />
        - All data found on our service belongs to us or has been given to us by our users for
        exploitation and processing and therefore, for the protection of our subscribers, may not be
        used or distributed outside the services provided by us without express written consent.<br />
        - You may not disrupt or interfere with the operation of the Service.<br />
        - You may not attempt to create accounts or access or collect information in an unauthorized
        manner. This includes creating accounts or collecting information in an automated manner
        without our express permission. You may not attempt to buy, sell, or transfer any part of
        your account (including your username) or solicit, collect, or use any credentials or badges
        from other users.<br />
        - You may not post any private or confidential information or do anything that infringes on
        the rights of another, including intellectual property rights.<br />
        - You may not use a domain name or URL in your username without our prior written consent.
        <br /><br />
        Rights to the content<br /><br />
        We do not claim ownership of any data provided to us for processing, but by using our
        service you grant us rights of use and exploitation.<br />
        - We are granted a non-exclusive, transferable, sub-licensable and worldwide license to use
        and exploit.<br />
        - We may host, use, distribute, modify, perform, copy, publicly perform or display,
        translate, and create derivative works of Your Content (subject to Your privacy and app
        settings) worldwide in compliance with the GDPR for the purpose of providing Our Service to
        You.<br />
        - You may terminate this license at any time by deleting your content or account. We are
        still permitted to use content that has already been licensed to us if you have shared it
        with others and they have not deleted it. To learn more about how we use your information
        and how you can control or delete your content, see our Privacy Policy. Or just ask our data
        protection officer.<br />
        - This also includes the use of your username, profile picture, and information about your
        relationships and actions with other accounts, advertisements, and sponsored content. Your
        username, profile picture, and information about your actions (such as 'likes') or
        relationships (such as subscribers) may be displayed alongside or in conjunction with
        accounts, advertisements, offers, and other sponsored content that you follow or interact
        with, without compensation to you. For example, we may show that you 'Liked' a sponsored
        post created by a brand that paid us to display its ads on Talents. <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions"
};
</script>

<style scoped lang="scss">
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
  }
  padding: 100px 0 0 0;
  width: 100vw;
  height: calc(100vh - 100px);
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 2em 3em;

  .header {
    font-size: 55px;
  }

  .text {
    max-width: 600px;
    font-size: 22px;
    padding-bottom: 3rem;
    white-space: pre-line;
  }
}

.background-icons {
  z-index: -5;
  position: absolute;
  opacity: 0.1;
  left: 0;
  bottom: 0;
  height: 3000px;
  user-select: none;
}
</style>
