import * as contentful from 'contentful'

const createClient = () => {
  const config = {
    space: process.env.VUE_APP_CTF_SPACE_ID,
    accessToken: process.env.VUE_APP_CTF_CDA_ACCESS_TOKEN,
    environment: process.env.VUE_APP_CTF_ENVIRONMENT_ID,
    host: 'cdn.contentful.com',
  }

  return contentful.createClient(config)
}

const fetchBlogs = async () => {
  return await createClient().getEntries({
    content_type: 'blog',
  })
}

export default {
  fetchBlogs,
}
