<template>
  <div class="footer-container">
    <div class="footer-links">
      <ul>
        <li><router-link to="/legalNotice">Legal Notice / Contact</router-link></li>
        <li><router-link to="/privacyPolicy">Privacy Policy</router-link></li>
        <li><router-link to="/termsAndConditions">Terms and Conditions</router-link></li>
        <!-- <li><router-link to="/faq">FAQ</router-link></li> -->
      </ul>
    </div>
    <p>2023 © talents.live - All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
@media (min-width: 1000px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    z-index: 99;
    width: 100%;
    position: absolute;
    bottom: 0;
    align-items: center;
    background-image: linear-gradient(40deg, #ffcb1f, #fd170a);
    padding: 15px 0 5px 0px;
    margin-bottom: -15px;

    p {
      color: white;
      font-size: min(2vh, 2vw);
      bottom: 50px;
    }
    .footer-links {
      ul {
        text-align: center;
        margin: 0;
        padding-right: 50px;

        li {
          font-size: min(2vh, 2vw);
          display: block;
          float: right;
          padding: 0 0.9em 0 0;

          a {
            color: white;
            text-decoration: none;
            font-weight: bold;

            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    z-index: 5;
    width: 100%;
    align-items: center;
    background-image: linear-gradient(40deg, #ffcb1f, #fd170a);
    padding: 15px 0 5px 0px;
    margin-bottom: -15px;
    border-radius: 25px 25px 0 0;

    p {
      color: white;
      font-size: 0.9rem;
      bottom: 50px;
    }
    .footer-links {
      ul {
        padding-right: 50px;
        margin: 0;
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;

        li {
          font-size: 0.8rem;
          display: block;

          a {
            color: white;
            text-decoration: none;
            font-weight: bold;

            &:hover {
              color: black;
            }
          }
        }
      }
    }
  }
}
</style>
