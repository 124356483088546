import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./views/Home.vue";
import TermsAndConditions from "./views/TermsAndConditions.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import LegalNotice from "./views/LegalNotice.vue";
// import FAQ from "./views/FAQ.vue";
import Ambassador from "./views/Ambassador.vue";
import Blog from "./views/Blog.vue";
import CodeGenerator from "./views/CodeGenerator.vue";

import LearnMore from "./views/learnMore/LearnMore.vue";
import LeaderboardLearnMore from "./views/learnMore/LeaderboardLearnMore.vue";
import challengesAndBattlesLearnMore from "./views/learnMore/ChallengesAndBattlesLearnMore.vue";
import FanbaseLearnMore from "./views/learnMore/FanbaseLearnMore.vue";
import ProfileLearnMore from "./views/learnMore/ProfileLearnMore.vue";
import UpcomingProjectLearnMore from "./views/learnMore/UpcomingProjectLearnMore.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage
  },
  {
    path: "/termsAndConditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/legalNotice",
    name: "LegalNotice",
    component: LegalNotice,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  // {
  //   path: "/faq",
  //   name: "FAQ",
  //   component: FAQ,
  // },
  {
    path: "/ambassador",
    name: "Ambassador",
    component: Ambassador,
  },
  {
    path: "/invitation-code-generator",
    name: "CodeGenerator",
    component: CodeGenerator,
  },
  {
    path: "/learn-more",
    name: "LearnMore",
    component: LearnMore,
    children: [
      {
        path: "leaderboard",
        name: "LeaderboardLearnMore",
        component: LeaderboardLearnMore
      },
      {
        path: "challengesAndBattles",
        name: "challengesAndBattlesLearnMore",
        component: challengesAndBattlesLearnMore
      },
      {
        path: "fanbase",
        name: "FanbaseLearnMore",
        component: FanbaseLearnMore
      },
      {
        path: "profile",
        name: "ProfileLearnMore",
        component: ProfileLearnMore
      },
      {
        path: "upcoming-project",
        name: "UpcomingProjectLearnMore",
        component: UpcomingProjectLearnMore
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
