<template>
  <div class="full-page">
    <div class="white-background"></div>
    <div class="content-container">
      <div class="cta-container">
        <button class="download-btn">Download Soon</button>
        <img class="logo" src="@/assets/img/logos/TalentsverseLogo.svg" />
        <h1 class="heading">
          <span class="the-missing-piece">Join The</span> <span class="you">Talentsverse!</span>
        </h1>
        <img class="devices-image" src="@/assets/img/footer/Devices.svg" />
      </div>

      <img class="star-image" src="@/assets/img/footer/StarOutline2.png" />
      <img class="characters-image" src="@/assets/img/footer/Characters.png" />
    </div>
    <img class="background-lines" src="@/assets/img/landingPage/BackgroundLinesDiagonal.svg" />
    <Footer />
  </div>
</template>

<script>
import Footer from '../Footer.vue';

export default {
  name: "FooterScreen",
  components: {
    Footer
  }
};
</script>

<style lang="scss" scoped>
.white-background {
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.content-container {
  display: grid;
  position: relative;
  width: 80%;
  max-height: 70%;
  z-index: 5;

  right: 0;
  left: 0;
  top: 45%;
  transform: translateY(-45%);
  margin: auto;

  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr;

  //Content Images start

  .star-image {
    width: 100%;
    grid-column: 1 / span 5;
    grid-row: 1 / span 2;
  }

  .characters-image {
    width: 100%;
    grid-column: 5 / span 7;
    grid-row: 1 / span 1;
  }
  //Content Images end

  // CTA start

  .cta-container {
    display: grid;
    position: relative;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column: 4 / span 8;
    grid-row: 2 / span 2;
    bottom: 50px;
    padding-left: 5%;

    .logo {
      display: none;
    }

    .download-btn {
      display: none;
    }

    .heading {
      display: flex;
      flex-direction: row;
      justify-content: left;
      padding: 0;
      margin: 0;
      width: 100%;
      bottom: 0;
      padding-bottom: 10px;

      grid-column: 1 / span 2;
      grid-row: 1 / span 1;
      font-family: "Noto Sans", sans-serif;
      font-size: 4vw;
      font-weight: 100;
      align-self: left;

      .the-missing-piece {
        margin-top: 50px;
      }

      .you {
        font-family: "Noto Sans", sans-serif;
        font-weight: 900;
        font-size: 4vw;
        background: -webkit-linear-gradient(40deg, #ffcb1f, #fd170a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: underline;
        text-decoration-color: #fd170a;
        align-self: center;
        padding-left: 20px;
        margin-top: 45px;
      }
    } //heading wrapper end

    .devices-image {
      width: 20%;
      align-self: start;
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }
  }
  // CTA end
} //Content Container end

.back-to-top-box {
  z-index: 150;
  position: absolute;
  width: 5%;
  bottom: 15%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px #8ebbff;
  padding: 20px;
  border-radius: 25px;

  grid-column: -2 / -1;
  grid-row: 5 / span 1;

  .back-to-top-text {
    display: inline-block;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .back-to-top-btn {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    user-select: none;
    width: 75%;
    transform: rotate(180deg);
    transition: 0.5s ease-in-out;
  }
  .back-to-top-btn:hover {
    transform: rotate(540deg);
  }
} // back to top button end

.background-lines {
  z-index: 0;
  position: absolute;
  float: right;
  left: 75%;
  height: 100vh;
  top: 0;
  opacity: 0.7;
}

//media query/////////////////////

@media all and (max-width: 750px) {
  .content-container {
    display: grid;
    position: relative;
    width: 80%;
    max-height: 70%;

    right: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;

    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;

    justify-items: center;
    align-items: center;
    padding-top: 10%;

    //Content Images start

    .star-image {
      display: none;
    }

    .characters-image {
      z-index: -1;
      position: absolute;
      width: 300%;
      justify-self: center;
      top: 60%;
      opacity: 50%;
      align-self: end;
    }
    //Content Images end

    // CTA start

    .cta-container {
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 1fr;

      position: relative;
      height: 100%;
      width: 100%;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      align-self: center;
      justify-self: center;
      overflow: auto;
      justify-items: center;
      align-items: center;

      margin-bottom: -10%;
      padding-top: 10%;
      box-shadow: 0px 0px 2vw #8ebbff;
      background-color: rgb(255, 255, 255, 0.8);
      //border-radius: 10vw;
      transition: 0.1s;

      .logo {
        z-index: 5;
        display: block;
        position: absolute;
        justify-self: center;
        width: 15vh;
        top: 5%;
      }

      .heading {
        display: grid;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 100%;

        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        font-family: "Noto Sans", sans-serif;
        font-size: 5vh;
        font-weight: 100;
        align-self: center;
        padding-bottom: 5%;

        .the-missing-piece {
          margin-bottom: -3.5vh;
        }

        .you {
          font-family: "Noto Sans", sans-serif;
          font-weight: 900;
          font-size: 5vh;
          background: -webkit-linear-gradient(40deg, #ffcb1f, #fd170a);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-decoration: underline;
          text-decoration-color: #fd170a;
          align-self: start;
          justify-self: center;
          padding: 0.75em 0 0 0;
          margin: 0;
        }
      } //heading wrapper end

      .download-btn {
        width: 50vw;
        height: 10vh;
        //max-height: 50px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        border-width: 0px;
        border-radius: 10vw;
        font-size: 6vw;
        font-weight: bold;
        color: white;
        background: black;
      }

      .devices-image {
        width: 15vh;
        align-self: start;
        justify-self: center;
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        padding-top: 4vw;
        padding-bottom: 2vh;
      }
    }
    // CTA end
  } //Content Container end

  .back-to-top-box {
    z-index: 150;
    position: absolute;
    width: 5%;
    bottom: 15%;
    right: 5%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 15px #8ebbff;
    padding: 20px;
    border-radius: 25px;
    display: none;

    grid-column: -2 / -1;
    grid-row: 5 / span 1;

    .back-to-top-text {
      display: inline-block;
      font-size: 0.9rem;
      text-transform: uppercase;
    }

    .back-to-top-btn {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      user-select: none;
      width: 75%;
      transform: rotate(180deg);
      transition: 0.5s ease-in-out;
    }
    .back-to-top-btn:hover {
      transform: rotate(540deg);
    }
  } // back to top button end

  .background-lines {
    display: none;
  }
}
</style>
