<template>
  <div class="full-page">
    <img class="background-stars" src="@/assets/img/profile/fallingStars.svg" />
    <div class="pc-version">
      <div class="content-container">
        <img class="background-frame" src="@/assets/img/profile/AppProfile.jpg" />
        <div class="right-side">
          <img class="background-jury" src="@/assets/img/profile/Jury.svg" />
          <div class="header-text">
            <div class="colored-text">
              Impress Your Community
            </div>
            <learn-more-btn path="/learn-more/profile" />
          </div>
        </div>
      </div>
    </div>

    <div class="phone-version">
      <learn-more-btn path="/learn-more/profile" />
      <div class="content-container">
        <img class="background-frame" src="@/assets/img/profile/AppProfile.jpg" />
        <div class="header-text">
          <div class="colored-text">
            Impress Your Community
          </div>
          <div class="learn-more">
            <learn-more-btn path="/learn-more/profile" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreBtn from "../LearnMoreBtn.vue";

export default {
  name: "ProfileDesign",
  components: {
    LearnMoreBtn
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1000px) {
  .phone-version {
    display: none;
  }

  .content-container {
    overflow-y: hidden;
    overflow-x: hidden;
    @media (max-width: 600px) {
      padding: 120px 3vw 0 3vw;
      width: calc(100vw - 6vw);
    }
    padding: 120px 75px 0 75px;
    width: calc(100vw - 150px);
    height: calc(100vh - 120px);
    justify-content: space-evenly;
    flex-direction: row;
    display: flex;
    gap: 1em;

    .background-frame {
      z-index: -3;
      align-self: flex-end;
      width: 18vw;
      height: 38vw;
      margin: auto 0 auto 0;
      border-radius: 30px;
      border: double 5px transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(
          140deg,
          rgb(255, 233, 5),
          rgb(255, 46, 9),
          rgb(255, 233, 5),
          rgb(255, 46, 9)
        );
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      .background-jury {
        z-index: -3;
        height: 50vh;
      }

      .header-text {
        font-size: max(3rem, 3.5vw);
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1000px) {

  .learn-more {
    display: flex;
    justify-content: center;
  }
  .pc-version {
    display: none;
  }

  .content-container {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    @media (max-width: 600px) {
      padding: 60px 2vw 0 2vw;
      width: calc(100vw - 4vw);
    }
    padding: 90px 75px 0 75px;
    width: calc(100vw - 150px);
    height: calc(100vh - 100px);
    flex-direction: column;
    display: flex;
    gap: 3vh;

    .background-frame {
      z-index: -3;
      width: min(65vw, 27vh);
      margin: 0 auto 0 auto;
      border-radius: 25px;
      border: double 5px transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(
          140deg,
          rgb(255, 233, 5),
          rgb(255, 46, 9),
          rgb(255, 233, 5),
          rgb(255, 46, 9)
        );
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    .header-text {
      font-size: 2.3em;
      font-weight: bold;
    }
  }
}

////////////////////////////////////

.background-stars {
  z-index: -3;
  height: 100vh;
  position: absolute;
  left: 0;
}
</style>
