<template>
  <div class="full-page">
    <img class="background-triangles" src="@/assets/img/theLeaderboard/TopTriangles.svg" />
    <img class="background-paper-plane" src="@/assets/img/theLeaderboard/PaperPlane.svg" />
    <div class="background-mountains-transparent" />
    <img class="background-mountains" src="@/assets/img/theLeaderboard/Mountains.svg" />
    <img class="background-sun" src="@/assets/img/theLeaderboard/Sun.svg" />
    <div class="content-container">
      <div class="header-text">
        <div class="first-word">
          <svg class="header" viewBox="0 0 439 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="compete">
              <g id="compete-text">
                <g id="c">
                  <path
                    id="compete_3"
                    d="M33.6672 13.2C30.8006 13.2 28.2339 13.7666 25.9672 14.9C23.7672 16.0333 21.9006 17.6666 20.3672 19.8C18.8339 21.8666 17.6672 24.4 16.8672 27.4C16.1339 30.4 15.7672 33.7666 15.7672 37.5C15.7672 42.5666 16.3672 46.9 17.5672 50.5C18.8339 54.0333 20.8006 56.7333 23.4672 58.6C26.1339 60.4666 29.5339 61.4 33.6672 61.4C36.6006 61.4 39.5006 61.0666 42.3672 60.4C45.3006 59.7333 48.4672 58.8 51.8672 57.6V70.3C48.7339 71.5667 45.6339 72.5 42.5672 73.1C39.5006 73.7 36.0672 74 32.2672 74C24.9339 74 18.8672 72.5 14.0672 69.5C9.3339 66.4333 5.8339 62.1666 3.56724 56.7C1.30057 51.1666 0.167236 44.7333 0.167236 37.4C0.167236 32 0.90057 27.0666 2.36724 22.6C3.8339 18.0666 5.96724 14.1666 8.76724 10.9C11.6339 7.63331 15.1339 5.09998 19.2672 3.29998C23.4672 1.49998 28.2672 0.599976 33.6672 0.599976C37.2006 0.599976 40.7339 1.06664 44.2672 1.99998C47.8672 2.86664 51.3006 4.06665 54.5672 5.59998L49.6672 17.9C47.0006 16.6333 44.3006 15.5333 41.5672 14.6C38.9006 13.6666 36.2672 13.2 33.6672 13.2Z"
                    fill="url(#paint0_linear_708_5)"
                  />
                </g>
                <g id="o">
                  <path
                    id="compete_4"
                    d="M115.439 45.6C115.439 50.1334 114.806 54.1667 113.539 57.7C112.339 61.2334 110.572 64.2334 108.239 66.7C105.972 69.1 103.206 70.9334 99.9391 72.2C96.6724 73.4 92.9724 74 88.8391 74C85.0391 74 81.5057 73.4 78.2391 72.2C75.0391 70.9334 72.2724 69.1 69.9391 66.7C67.6057 64.2334 65.7724 61.2334 64.4391 57.7C63.1724 54.1667 62.5391 50.1334 62.5391 45.6C62.5391 39.5334 63.6057 34.4334 65.7391 30.3C67.8724 26.1 70.9391 22.9 74.9391 20.7C78.9391 18.5 83.6724 17.4 89.1391 17.4C94.2724 17.4 98.8057 18.5 102.739 20.7C106.672 22.9 109.772 26.1 112.039 30.3C114.306 34.4334 115.439 39.5334 115.439 45.6ZM77.7391 45.6C77.7391 49.2 78.1057 52.2334 78.8391 54.7C79.6391 57.1 80.8724 58.9334 82.5391 60.2C84.2057 61.4 86.3724 62 89.0391 62C91.7057 62 93.8391 61.4 95.4391 60.2C97.1057 58.9334 98.3057 57.1 99.0391 54.7C99.8391 52.2334 100.239 49.2 100.239 45.6C100.239 42 99.8391 39 99.0391 36.6C98.3057 34.2 97.1057 32.4 95.4391 31.2C93.7724 30 91.6057 29.4 88.9391 29.4C85.0057 29.4 82.1391 30.7667 80.3391 33.5C78.6057 36.1667 77.7391 40.2 77.7391 45.6Z"
                    fill="url(#paint1_linear_708_5)"
                  />
                </g>
                <g id="m">
                  <path
                    id="compete_5"
                    d="M191.853 17.4C198.053 17.4 202.72 19 205.853 22.2C209.053 25.3334 210.653 30.4 210.653 37.4V73H195.753V41.1C195.753 37.1667 195.087 34.2334 193.753 32.3C192.42 30.3 190.353 29.3 187.553 29.3C183.62 29.3 180.82 30.7 179.153 33.5C177.487 36.3 176.653 40.3334 176.653 45.6V73H161.753V41.1C161.753 38.5 161.453 36.3334 160.853 34.6C160.253 32.8667 159.353 31.5667 158.153 30.7C156.953 29.7667 155.42 29.3 153.553 29.3C150.82 29.3 148.653 30 147.053 31.4C145.453 32.8 144.32 34.8334 143.653 37.5C142.987 40.1667 142.653 43.4334 142.653 47.3V73H127.753V18.4H139.153L141.153 25.4H141.953C143.087 23.5334 144.487 22.0334 146.153 20.9C147.82 19.7 149.653 18.8334 151.653 18.3C153.72 17.7 155.786 17.4 157.853 17.4C161.853 17.4 165.253 18.0667 168.053 19.4C170.853 20.6667 172.987 22.6667 174.453 25.4H175.753C177.42 22.6 179.72 20.5667 182.653 19.3C185.653 18.0334 188.72 17.4 191.853 17.4Z"
                    fill="url(#paint2_linear_708_5)"
                  />
                </g>
                <g id="p">
                  <path
                    id="compete_6"
                    d="M256.395 17.4C262.529 17.4 267.495 19.8 271.295 24.6C275.095 29.3334 276.995 36.3334 276.995 45.6C276.995 51.8 276.095 57.0334 274.295 61.3C272.495 65.5 270.029 68.6667 266.895 70.8C263.762 72.9334 260.129 74 255.995 74C253.395 74 251.129 73.6667 249.195 73C247.329 72.3334 245.729 71.4667 244.395 70.4C243.062 69.3334 241.895 68.2 240.895 67H240.095C240.362 68.2667 240.562 69.6 240.695 71C240.829 72.3334 240.895 73.6667 240.895 75V97H225.995V18.4H238.095L240.195 25.5H240.895C241.895 24.0334 243.095 22.7 244.495 21.5C245.895 20.2334 247.562 19.2334 249.495 18.5C251.495 17.7667 253.795 17.4 256.395 17.4ZM251.595 29.3C248.995 29.3 246.929 29.8334 245.395 30.9C243.862 31.9667 242.729 33.6 241.995 35.8C241.329 37.9334 240.962 40.6334 240.895 43.9V45.5C240.895 49.0334 241.229 52.0334 241.895 54.5C242.562 56.9 243.695 58.7334 245.295 60C246.895 61.2667 249.062 61.9 251.795 61.9C254.062 61.9 255.929 61.2667 257.395 60C258.862 58.7334 259.962 56.8667 260.695 54.4C261.429 51.9334 261.795 48.9334 261.795 45.4C261.795 40.0667 260.962 36.0667 259.295 33.4C257.695 30.6667 255.129 29.3 251.595 29.3Z"
                    fill="url(#paint3_linear_708_5)"
                  />
                </g>
                <g id="e">
                  <path
                    id="compete_7"
                    d="M311.777 17.4C316.843 17.4 321.177 18.3667 324.777 20.3C328.443 22.2334 331.277 25.0334 333.277 28.7C335.277 32.3667 336.277 36.8667 336.277 42.2V49.4H301.077C301.21 53.6 302.443 56.9 304.777 59.3C307.177 61.7 310.477 62.9 314.677 62.9C318.21 62.9 321.41 62.5667 324.277 61.9C327.143 61.1667 330.11 60.0667 333.177 58.6V70.1C330.51 71.4334 327.677 72.4 324.677 73C321.743 73.6667 318.177 74 313.977 74C308.51 74 303.677 73 299.477 71C295.277 68.9334 291.977 65.8334 289.577 61.7C287.177 57.5667 285.977 52.3667 285.977 46.1C285.977 39.7 287.043 34.4 289.177 30.2C291.377 25.9334 294.41 22.7334 298.277 20.6C302.143 18.4667 306.643 17.4 311.777 17.4ZM311.877 28C309.01 28 306.61 28.9334 304.677 30.8C302.81 32.6667 301.71 35.5667 301.377 39.5H322.277C322.277 37.3 321.877 35.3334 321.077 33.6C320.343 31.8667 319.21 30.5 317.677 29.5C316.143 28.5 314.21 28 311.877 28Z"
                    fill="url(#paint4_linear_708_5)"
                  />
                </g>
                <g id="t">
                  <path
                    id="compete_8"
                    d="M371.359 62.0999C373.025 62.0999 374.625 61.9333 376.159 61.5999C377.692 61.2666 379.225 60.8666 380.759 60.3999V71.4999C379.159 72.1666 377.159 72.7333 374.759 73.1999C372.425 73.7333 369.859 73.9999 367.059 73.9999C363.792 73.9999 360.859 73.4666 358.259 72.3999C355.725 71.3333 353.692 69.4999 352.159 66.8999C350.692 64.2333 349.959 60.5666 349.959 55.8999V29.5999H342.859V23.2999L351.059 18.2999L355.359 6.79993H364.859V18.3999H380.159V29.5999H364.859V55.8999C364.859 57.9666 365.459 59.5333 366.659 60.5999C367.859 61.5999 369.425 62.0999 371.359 62.0999Z"
                    fill="url(#paint5_linear_708_5)"
                  />
                </g>
                <g id="e2">
                  <path
                    id="compete_9"
                    d="M414.218 17.4C419.285 17.4 423.618 18.3667 427.218 20.3C430.885 22.2334 433.718 25.0334 435.718 28.7C437.718 32.3667 438.718 36.8667 438.718 42.2V49.4H403.518C403.651 53.6 404.885 56.9 407.218 59.3C409.618 61.7 412.918 62.9 417.118 62.9C420.651 62.9 423.851 62.5667 426.718 61.9C429.585 61.1667 432.551 60.0667 435.618 58.6V70.1C432.951 71.4334 430.118 72.4 427.118 73C424.185 73.6667 420.618 74 416.418 74C410.951 74 406.118 73 401.918 71C397.718 68.9334 394.418 65.8334 392.018 61.7C389.618 57.5667 388.418 52.3667 388.418 46.1C388.418 39.7 389.485 34.4 391.618 30.2C393.818 25.9334 396.851 22.7334 400.718 20.6C404.585 18.4667 409.085 17.4 414.218 17.4ZM414.318 28C411.451 28 409.051 28.9334 407.118 30.8C405.251 32.6667 404.151 35.5667 403.818 39.5H424.718C424.718 37.3 424.318 35.3334 423.518 33.6C422.785 31.8667 421.651 30.5 420.118 29.5C418.585 28.5 416.651 28 414.318 28Z"
                    fill="url(#paint6_linear_708_5)"
                  />
                </g>
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_708_5"
                x1="143.552"
                y1="49.1654"
                x2="443.016"
                y2="51.34"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_708_5"
                x1="143.552"
                y1="49.1655"
                x2="443.016"
                y2="51.3401"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_708_5"
                x1="143.552"
                y1="49.1654"
                x2="443.016"
                y2="51.34"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_708_5"
                x1="143.552"
                y1="49.1655"
                x2="443.016"
                y2="51.3401"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_708_5"
                x1="143.552"
                y1="49.1654"
                x2="443.016"
                y2="51.34"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_708_5"
                x1="143.552"
                y1="49.1655"
                x2="443.016"
                y2="51.3401"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_708_5"
                x1="143.552"
                y1="49.1654"
                x2="443.016"
                y2="51.34"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA439" />
                <stop offset="1" stop-color="#FF853D" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="second-word">
          <svg viewBox="0 0 398 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="climb">
              <g id="climb-text">
                <g id="c">
                  <path
                    id="c_2"
                    d="M48.5608 23.888C44.4328 23.888 40.7368 24.704 37.4728 26.336C34.3048 27.968 31.6168 30.32 29.4088 33.392C27.2008 36.368 25.5208 40.016 24.3688 44.336C23.3128 48.656 22.7848 53.504 22.7848 58.88C22.7848 66.176 23.6488 72.416 25.3768 77.6C27.2008 82.688 30.0328 86.576 33.8728 89.264C37.7128 91.952 42.6088 93.296 48.5608 93.296C52.7848 93.296 56.9608 92.816 61.0888 91.856C65.3128 90.896 69.8728 89.552 74.7688 87.824V106.112C70.2568 107.936 65.7928 109.28 61.3768 110.144C56.9608 111.008 52.0168 111.44 46.5448 111.44C35.9848 111.44 27.2488 109.28 20.3368 104.96C13.5208 100.544 8.4808 94.4 5.2168 86.528C1.9528 78.56 0.320801 69.296 0.320801 58.736C0.320801 50.96 1.3768 43.856 3.4888 37.424C5.6008 30.896 8.6728 25.28 12.7048 20.576C16.8328 15.872 21.8728 12.224 27.8248 9.63202C33.8728 7.04002 40.7848 5.74402 48.5608 5.74402C53.6488 5.74402 58.7368 6.41602 63.8248 7.76002C69.0088 9.00802 73.9528 10.736 78.6568 12.944L71.6008 30.656C67.7608 28.832 63.8728 27.248 59.9368 25.904C56.0968 24.56 52.3048 23.888 48.5608 23.888Z"
                    fill="url(#paint0_linear_710_21)"
                  />
                </g>
                <g id="l">
                  <path
                    id="Climb"
                    d="M116.344 110H94.8882V0.560059H116.344V110Z"
                    fill="url(#paint1_linear_710_21)"
                  />
                </g>
                <g id="i">
                  <path
                    id="Climb_2"
                    d="M160.219 31.3761V110H138.763V31.3761H160.219ZM149.563 0.560059C152.731 0.560059 155.467 1.32806 157.771 2.86406C160.075 4.30406 161.227 7.04006 161.227 11.0721C161.227 15.0081 160.075 17.7441 157.771 19.2801C155.467 20.8161 152.731 21.5841 149.563 21.5841C146.299 21.5841 143.515 20.8161 141.211 19.2801C139.003 17.7441 137.899 15.0081 137.899 11.0721C137.899 7.04006 139.003 4.30406 141.211 2.86406C143.515 1.32806 146.299 0.560059 149.563 0.560059Z"
                    fill="url(#paint2_linear_710_21)"
                  />
                </g>
                <g id="m">
                  <path
                    id="Climb_3"
                    d="M274.942 29.936C283.87 29.936 290.59 32.24 295.102 36.848C299.71 41.36 302.014 48.656 302.014 58.736V110H280.558V64.064C280.558 58.4 279.598 54.176 277.678 51.392C275.758 48.512 272.782 47.072 268.75 47.072C263.086 47.072 259.054 49.088 256.654 53.12C254.254 57.152 253.054 62.96 253.054 70.544V110H231.598V64.064C231.598 60.32 231.166 57.2 230.302 54.704C229.438 52.208 228.142 50.336 226.414 49.088C224.686 47.744 222.478 47.072 219.79 47.072C215.854 47.072 212.734 48.08 210.43 50.096C208.126 52.112 206.494 55.04 205.534 58.88C204.574 62.72 204.094 67.424 204.094 72.992V110H182.638V31.376H199.054L201.934 41.456H203.086C204.718 38.768 206.734 36.608 209.134 34.976C211.534 33.248 214.174 32 217.054 31.232C220.03 30.368 223.006 29.936 225.982 29.936C231.742 29.936 236.638 30.896 240.67 32.816C244.702 34.64 247.774 37.52 249.886 41.456H251.758C254.158 37.424 257.47 34.496 261.694 32.672C266.014 30.848 270.43 29.936 274.942 29.936Z"
                    fill="url(#paint3_linear_710_21)"
                  />
                </g>
                <g id="b">
                  <path
                    id="Climb_4"
                    d="M345.563 26.0481C345.563 29.0241 345.467 31.9521 345.275 34.8321C345.083 37.7121 344.891 39.9681 344.699 41.6001H345.563C347.675 38.3361 350.507 35.6001 354.059 33.3921C357.611 31.0881 362.219 29.9361 367.883 29.9361C376.715 29.9361 383.867 33.3921 389.339 40.3041C394.811 47.1201 397.547 57.2001 397.547 70.5441C397.547 79.4721 396.251 87.0081 393.659 93.1521C391.163 99.2001 387.659 103.76 383.147 106.832C378.635 109.904 373.355 111.44 367.307 111.44C361.547 111.44 356.987 110.432 353.627 108.416C350.363 106.304 347.675 103.952 345.563 101.36H344.123L340.523 110H324.107V55.2801V0.560059H345.563V26.0481ZM360.971 47.0721C357.227 47.0721 354.251 47.8401 352.043 49.3761C349.835 50.9121 348.203 53.2641 347.147 56.4321C346.187 59.5041 345.659 63.3921 345.563 68.0961V70.4001C345.563 77.9841 346.667 83.8401 348.875 87.9681C351.179 92.0001 355.307 94.0161 361.259 94.0161C365.675 94.0161 369.179 92.0001 371.771 87.9681C374.363 83.8401 375.659 77.9361 375.659 70.2561C375.659 62.5761 374.315 56.8161 371.627 52.9761C369.035 49.0401 365.483 47.0721 360.971 47.0721Z"
                    fill="url(#paint4_linear_710_21)"
                  />
                </g>
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_710_21"
                x1="394.798"
                y1="-43.9999"
                x2="23.7261"
                y2="-6.32555"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_710_21"
                x1="394.798"
                y1="-43.9998"
                x2="23.7261"
                y2="-6.32546"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_710_21"
                x1="394.798"
                y1="-44"
                x2="23.7261"
                y2="-6.3256"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_710_21"
                x1="394.798"
                y1="-43.9998"
                x2="23.7261"
                y2="-6.32546"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_710_21"
                x1="394.798"
                y1="-43.9999"
                x2="23.7261"
                y2="-6.32552"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="third-word">
          <svg viewBox="0 0 510 230" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="cash">
              <g id="cash-text">
                <g id="c">
                  <path
                    id="Cash"
                    d="M85.6753 35.224C79.5979 35.224 74.1566 36.4253 69.3513 38.828C64.6873 41.2306 60.7299 44.6933 57.4793 49.216C54.2286 53.5973 51.7553 58.968 50.0593 65.328C48.5046 71.688 47.7273 78.8253 47.7273 86.74C47.7273 97.4813 48.9993 106.668 51.5433 114.3C54.2286 121.791 58.3979 127.515 64.0513 131.472C69.7046 135.429 76.9126 137.408 85.6753 137.408C91.8939 137.408 98.0419 136.701 104.119 135.288C110.338 133.875 117.051 131.896 124.259 129.352V156.276C117.617 158.961 111.045 160.94 104.543 162.212C98.0419 163.484 90.7633 164.12 82.7073 164.12C67.1606 164.12 54.2993 160.94 44.1233 154.58C34.0886 148.079 26.6686 139.033 21.8633 127.444C17.0579 115.713 14.6553 102.075 14.6553 86.528C14.6553 75.08 16.2099 64.6213 19.3193 55.152C22.4286 45.5413 26.9513 37.2733 32.8873 30.348C38.9646 23.4226 46.3846 18.052 55.1473 14.236C64.0513 10.42 74.2273 8.51196 85.6753 8.51196C93.1659 8.51196 100.657 9.5013 108.147 11.48C115.779 13.3173 123.058 15.8613 129.983 19.112L119.595 45.188C113.942 42.5026 108.218 40.1706 102.423 38.192C96.7699 36.2133 91.1873 35.224 85.6753 35.224Z"
                    fill="url(#paint0_linear_712_35)"
                  />
                </g>
                <g id="a">
                  <path
                    id="Cash_2"
                    d="M201.368 43.916C216.914 43.916 228.786 47.308 236.984 54.092C245.322 60.7347 249.492 70.9814 249.492 84.832V162H227.444L221.296 146.312H220.448C217.197 150.411 213.805 153.803 210.272 156.488C206.88 159.173 202.922 161.081 198.4 162.212C194.018 163.484 188.577 164.12 182.076 164.12C175.292 164.12 169.144 162.848 163.632 160.304C158.261 157.619 154.021 153.591 150.912 148.22C147.802 142.708 146.248 135.783 146.248 127.444C146.248 115.148 150.558 106.103 159.18 100.308C167.801 94.372 180.733 91.1214 197.976 90.556L218.116 89.92V84.832C218.116 78.7547 216.49 74.3027 213.24 71.476C210.13 68.6494 205.749 67.236 200.096 67.236C194.442 67.236 188.93 68.084 183.56 69.78C178.189 71.3347 172.818 73.3134 167.448 75.716L157.06 54.304C163.278 51.0533 170.133 48.5094 177.624 46.672C185.256 44.8347 193.17 43.916 201.368 43.916ZM205.82 108.788C195.644 109.071 188.577 110.908 184.62 114.3C180.662 117.692 178.684 122.144 178.684 127.656C178.684 132.461 180.097 135.924 182.924 138.044C185.75 140.023 189.425 141.012 193.948 141.012C200.732 141.012 206.456 139.033 211.12 135.076C215.784 130.977 218.116 125.253 218.116 117.904V108.364L205.82 108.788Z"
                    fill="url(#paint1_linear_712_35)"
                  />
                </g>
                <g id="s">
                  <path
                    id="Cash_3"
                    d="M362.597 127.656C362.597 135.429 360.76 142.072 357.085 147.584C353.41 152.955 347.898 157.053 340.549 159.88C333.2 162.707 324.084 164.12 313.201 164.12C305.145 164.12 298.22 163.555 292.425 162.424C286.63 161.435 280.836 159.739 275.041 157.336V131.26C281.26 134.087 287.973 136.419 295.181 138.256C302.389 140.093 308.749 141.012 314.261 141.012C320.48 141.012 324.861 140.093 327.405 138.256C330.09 136.419 331.433 134.016 331.433 131.048C331.433 129.069 330.868 127.303 329.737 125.748C328.748 124.193 326.486 122.427 322.953 120.448C319.42 118.469 313.908 115.925 306.417 112.816C299.209 109.707 293.273 106.668 288.609 103.7C283.945 100.591 280.482 96.9161 278.221 92.6761C275.96 88.2947 274.829 82.8534 274.829 76.3521C274.829 65.6107 278.998 57.5547 287.337 52.1841C295.676 46.8134 306.77 44.1281 320.621 44.1281C327.829 44.1281 334.684 44.8347 341.185 46.2481C347.686 47.6614 354.4 49.9934 361.325 53.2441L351.785 75.9281C347.969 74.2321 344.294 72.7481 340.761 71.4761C337.228 70.2041 333.765 69.2147 330.373 68.5081C327.122 67.8014 323.73 67.4481 320.197 67.4481C315.533 67.4481 312 68.0841 309.597 69.3561C307.336 70.6281 306.205 72.5361 306.205 75.0801C306.205 76.9174 306.77 78.6134 307.901 80.1681C309.173 81.5814 311.505 83.1361 314.897 84.8321C318.43 86.5281 323.589 88.7894 330.373 91.6161C337.016 94.3014 342.74 97.1281 347.545 100.096C352.35 102.923 356.025 106.527 358.569 110.908C361.254 115.148 362.597 120.731 362.597 127.656Z"
                    fill="url(#paint2_linear_712_35)"
                  />
                </g>
                <g id="h">
                  <path
                    id="Cash_4"
                    d="M418.792 33.74C418.792 39.3933 418.58 44.8347 418.156 50.064C417.873 55.2933 417.591 58.968 417.308 61.088H419.004C421.548 57.1307 424.516 53.9507 427.908 51.548C431.3 49.004 435.045 47.1667 439.144 46.036C443.384 44.764 447.836 44.128 452.5 44.128C460.839 44.128 468.117 45.612 474.336 48.58C480.555 51.548 485.36 56.1413 488.752 62.36C492.285 68.5787 494.052 76.6347 494.052 86.528V162H462.464V94.372C462.464 86.0333 460.909 79.8147 457.8 75.716C454.832 71.476 450.168 69.356 443.808 69.356C437.448 69.356 432.431 70.84 428.756 73.808C425.223 76.776 422.679 81.0867 421.124 86.74C419.569 92.3933 418.792 99.3187 418.792 107.516V162H387.204V0.880005H418.792V33.74Z"
                    fill="url(#paint3_linear_712_35)"
                  />
                </g>
                <g id="underline">
                  <path
                    id="Cash_5"
                    d="M0.359375 188H507.952V198.6H0.359375V188Z"
                    fill="url(#paint4_linear_712_35)"
                  />
                </g>
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_712_35"
                x1="498.682"
                y1="-64.9998"
                x2="40.8333"
                y2="-26.2222"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_712_35"
                x1="498.682"
                y1="-64.9998"
                x2="40.8333"
                y2="-26.2221"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_712_35"
                x1="498.682"
                y1="-64.9996"
                x2="40.8334"
                y2="-26.222"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_712_35"
                x1="498.682"
                y1="-64.9996"
                x2="40.8333"
                y2="-26.222"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_712_35"
                x1="496.682"
                y1="-65.4998"
                x2="38.8333"
                y2="-26.7221"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF003D" stop-opacity="0.901639" />
                <stop offset="1" stop-color="#FFA438" />
              </linearGradient>
            </defs>
          </svg>

          <div class="text-under-line" />
        </div>
      </div>
      <div class="description-text">
        turn your talent into a career
      </div>
      <div class="learnMore">
        <learn-more-btn path="/learn-more/leaderboard" />
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreBtn from "../LearnMoreBtn.vue";

export default {
  name: "Leaderboard",
  components: {
    LearnMoreBtn
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 850px) {
  .content-container {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 25%;

    .header-text {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      gap: 3.5vw;
      font-weight: bold;

      .first-word {
        width: 22vw;
        //max-width: 350px;
        background: linear-gradient(90deg, rgb(255, 190, 11), rgb(255, 140, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;

        g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
        }
        g:nth-child(2) path {
          animation-delay: 0s;
        }
        g:nth-child(3) path {
          animation-delay: 0.1s;
        }
        g:nth-child(4) path {
          animation-delay: 0.2s;
        }
        g:nth-child(5) path {
          animation-delay: 0.3s;
        }
        g:nth-child(6) path {
          animation-delay: 0.4s;
        }
        g:nth-child(7) path {
          animation-delay: .5s;
        }
        g:nth-child(8) path {
          animation-delay: .6s;
        }
      }
      .second-word {
        width: 26vw;
        background: linear-gradient(90deg, rgb(255, 140, 9), rgb(255, 119, 8));
        background-clip: text;
        -webkit-text-fill-color: transparent;

        g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
          animation-delay: .7s;
        }
        g:nth-child(2) path {
          animation-delay: .8s;
        }
        g:nth-child(3) path {
          animation-delay: .9s;
        }
        g:nth-child(4) path {
          animation-delay: 1s;
        }
        g:nth-child(5) path {
          animation-delay: 1.1s;
        }
        g:nth-child(6) path {
          animation-delay: 1.2s;
        }
      }
      .third-word {
        margin: 0;
        padding: 0;
        width: 30vw;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;

        g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
          animation-delay: 1.3s;
        }
        g:nth-child(2) path {
          animation-delay: 1.4s;
        }
        g:nth-child(3) path {
          animation-delay: 1.5s;
        }
        g:nth-child(4) path {
          animation-delay: 1.6s;
        }
        g:nth-child(5) path {
          animation-delay: 1.9s;
        }

        // .text-under-line {
        //   width: 93%;
        //   height: 10px;
        //   bottom: 10px;
        //   left: 5%;
        //   position: absolute;
        //   background-image: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        // }
      }
    }
    .description-text {
      padding-top: 10px;
      font-size: 3vw;
      color: rgb(58, 58, 58);
      animation: wiggle 3s ease-in-out infinite alternate;
    }

    .learnMore {
      margin-top: 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 850px) {
  .content-container {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 25%;

    .header-text {
      font-weight: bold;

      .first-word {
        width: 175px;
        //font-size: 50px;
        background: linear-gradient(90deg, rgb(255, 190, 11), rgb(255, 140, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: auto;
        
        g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
        }
        g:nth-child(2) path {
          animation-delay: 0s;
        }
        g:nth-child(3) path {
          animation-delay: 0.1s;
        }
        g:nth-child(4) path {
          animation-delay: 0.2s;
        }
        g:nth-child(5) path {
          animation-delay: 0.3s;
        }
        g:nth-child(6) path {
          animation-delay: 0.4s;
        }
        g:nth-child(7) path {
          animation-delay: .5s;
        }
        g:nth-child(8) path {
          animation-delay: .6s;
        }
      
      
      }

      .second-word {
        width: 225px;
        //font-size: 100px;
        background: linear-gradient(90deg, rgb(255, 140, 9), rgb(255, 119, 8));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: auto;

         g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
          animation-delay: .7s;
        }
        g:nth-child(2) path {
          animation-delay: .8s;
        }
        g:nth-child(3) path {
          animation-delay: .9s;
        }
        g:nth-child(4) path {
          animation-delay: 1s;
        }
        g:nth-child(5) path {
          animation-delay: 1.1s;
        }
        g:nth-child(6) path {
          animation-delay: 1.2s;
        }
      }

      .third-word {
        width: 300px;
        //font-size: 135px;
        background: linear-gradient(90deg, rgb(255, 119, 8), rgb(255, 46, 9));
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: auto;

        g {
          path {
            transform: translateY(20px);
          }
        }
        g:nth-child(1) path {
          animation: hop 4s alternate infinite;
          animation-delay: 1.3s;
        }
        g:nth-child(2) path {
          animation-delay: 1.4s;
        }
        g:nth-child(3) path {
          animation-delay: 1.5s;
        }
        g:nth-child(4) path {
          animation-delay: 1.6s;
        }
        g:nth-child(5) path {
          animation-delay: 1.9s;
        }

        // .text-under-line {
        //   display: none;
        // }
      }
    }

    .description-text {
      font-size: 30px;
      margin: 0 25px 0 25px;
      color: rgb(58, 58, 58);
      animation: wiggle 3s ease-in-out infinite alternate;
    }

    .learnMore {
      margin-top: 35px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

////////////////////////////////////

.background-paper-plane {
  position: absolute;
  width: 38vw;
  top: 17vh;
  left: 0;
}

@media (max-width: 850px) {
  .background-paper-plane {
    opacity: 0.3;
  }
}

.background-triangles {
  z-index: -1;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
}

.background-sun {
  z-index: -5;
  position: absolute;
  height: 30vh;
  width: 100vw;
  left: 10vw;
  bottom: 28vh;
  animation: spin 30s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

.background-mountains {
  z-index: -3;
  width: 100vw;
  position: absolute;
  bottom: 0;
  right: 0;
}

.background-mountains-transparent {
  z-index: -2;
  width: 100vw;
  position: absolute;
  height: 100vh;
  width: 100%;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.198) 0%, rgba(255, 255, 255, 1) 100%);

  @keyframes hop {
  20%,
  100% {
    transform: translate(0, 20px); /* stay on the baseline for most of the animation duration */
  }
  0% {
    transform: translate(0, 0px); /* hop up */
  }
  10% {
    transform: translate(0, 20px); /* return to baseline */
  }
}

    @keyframes wiggle {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}
}
</style>
