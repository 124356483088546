<template>
  <div>
    <img class="background-icons" src="@/assets/img/BattlesAndChallenges/IconCollection.svg" />
    <div class="content-container">
      <h1 class="header">
        Legal Notice
      </h1>

      <div class="text">
        <h2>Company details</h2>
        <p></p>

        <p>Talentsverse FZ Company</p>
        <p>Silicon Oasis Dubai</p>
        <p><b>CEO</b> Hicham Aouraghe</p>

        <h2>Contact</h2>
        <p></p>
        <p>E-Mail: info@talents.live</p>
        <p>&nbsp;</p>

        <h3>Liability for contents</h3>
        <p>
          As a service provider, we are responsible for our own content on these pages in accordance
          with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG).
          According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to
          monitor transmitted or stored third-party information or to investigate circumstances that
          indicate illegal activity.
        </p>
        <p>
          Obligations to remove or block the use of information according to general laws remain
          unaffected. However, liability in this regard is only possible from the point in time at
          which a concrete infringement of the law becomes known. If we become aware of any such
          infringements, we will remove this content immediately.
        </p>
        <p>&nbsp;</p>
        <h3>Liability for links</h3>
        <p>
          Our offer contains links to external websites of third parties, on whose contents we have
          no influence. Therefore, we cannot assume any liability for these external contents. The
          respective provider or operator of the pages is always responsible for the content of the
          linked pages. The linked pages were checked for possible legal violations at the time of
          linking. Illegal contents were not recognizable at the time of linking.
        </p>
        <p>
          However, a permanent control of the contents of the linked pages is not reasonable without
          concrete evidence of a violation of the law. If we become aware of any infringements, we
          will remove such links immediately.
        </p>
        <p>&nbsp;</p>
        <h3>Copyright</h3>
        <p>
          The content and works created by the site operators on these pages are subject to German
          copyright law. The reproduction, editing, distribution and any kind of exploitation
          outside the limits of copyright require the written consent of the respective author or
          creator. Downloads and copies of this site are only permitted for private, non-commercial
          use.
        </p>
        <p>
          Insofar as the content on this site was not created by the operator, the copyrights of
          third parties are respected. In particular, third-party content is identified as such.
          Should you nevertheless become aware of a copyright infringement, please inform us
          accordingly. If we become aware of any infringements, we will remove such content
          immediately.
        </p>
        <p>&nbsp;</p>
        <p>
          Source: <a href="https://www.e-recht24.de" target="_blank">https://www.e-recht24.de</a>
        </p>
        <p>
          Translated by: <a href="https://www.deepl.com" target="_blank">www.deepl.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalNotice"
};
</script>

<style scoped lang="scss">
.content-container {
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 100px 20px 0 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);
  }
  padding: 100px 0 0 0;
  width: 100vw;
  height: calc(100vh - 100px);
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 2em 3em;

  .header {
    font-size: 55px;
  }

  .text {
    max-width: 800px;
    font-size: 22px;
    padding-bottom: 3rem;
  }
}

.background-icons {
  z-index: -5;
  position: absolute;
  opacity: 0.1;
  left: -4000px;
  bottom: 0px;
  height: 5000px;
  user-select: none;
}
</style>
